.breadcrumbs {
  line-height: 20px;
  padding: 0 0 20px;
}

.breadcrumbs li {
  display: inline-block;
  padding: 0 5px 0 0;
}

.breadcrumbs li:before {
  display: none;
}

.breadcrumbs li:after {
  content: '>';
  background: none;
  display: inline-block;
  vertical-align: middle;
  height: auto;
  width: auto;
  position: static;
  margin-left: 5px;
}

.breadcrumbs li:last-child:after {
  display: none;
}

.breadcrumbs a {
  text-decoration: none;
}

.breadcrumbs a:hover {
  color: var(--pink);
}

@media (max-width: 767px) {
  .breadcrumbs a {
    color: var(--pink);
  }

  .breadcrumbs li:last-child {
    display: none;
  }
}