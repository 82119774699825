.product-list,
.product-list.swiper {
  display: flex;
  margin-left: -30px;
  padding-bottom: 20px;
}

.product-list {
  flex-wrap: wrap;
}

.product-list.swiper {
  flex-wrap: nowrap;
}

.product-list .swiper-wrapper {
  width: auto;
  height: auto;
  transition: all 0.25s ease 0s;
}

.product-list .swiper-slide {
  display: flex;
  width: 160px;

  @media (min-width: 767px) {
    width: 192px;

    .top-sales-products & {
      width: 256px;
    }
  }

  @media (min-width: 1025px) {
    width: 276px;

    .top-sales-products & {
      width: 208px;
    }
  }
}

.product-list.swiper .product-list-item {
  flex: 1;
}

.product-list-item {
  width: 25%;
  padding-left: 30px;
  padding-bottom: 30px;
  position: relative;
  display: flex;
  align-content: center;
}

.swiper-initialized.product-list-item {
  width: 100%;
}

.product-list .swiper-button-prev,
.product-list .swiper-button-next {
  background: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  display: block;
  width: 40px;
  height: 140px;
  box-shadow: 0 2px 7px var(--smoke);
  z-index: 2;
  border: 0;
  padding: 0;
  overflow: hidden;
  text-indent: -9999px;
  transition: opacity 0.1s linear;
  position: absolute;
  top: 50%;
  margin-top: -75px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url(../images/icon-arrow-right.svg) no-repeat 50% 50%;
  }

  &.swiper-button-disabled {
    opacity: 0;
    cursor: default;
  }
}

.product-list .swiper-button-prev{
  left: 0;

  &:before {
    transform: rotate(180deg);
  }
}

.product-list .swiper-button-next{
  right: 0;
}

.product-list a {
  text-decoration: none;
}

.product-list .image-holder {
  margin-bottom: 20px;
  position: relative;
}

.product-list .image-holder img {
  display: block;
  width: 100%;
}

.product-list .vendor-code {
  color: var(--dark-gray);
  font-size: 12px;
  display: none;
  margin-right: 5px;

  @media (min-width: 768px) {
    display: block;
  }
}

.product-list .product-title {
  display: block;
  font-weight: 500;
  text-transform: uppercase;
  word-break: break-word;
  flex: 1;
}

.product-list .product-title a:hover {
  color: var(--pink);
}

.product-list p {
  margin-bottom: .3em;
}

.product-list .product-status {
  font-size: 13px;
  font-weight: 500;
  color: var(--green);
}

.product-list .price-row {
  margin-bottom: 1em;
}

.product-list .price-row .price {
  font-size: 24px;
  font-weight: 500;
  position: relative;
}

.product-list .price-row .price-area {
  position: relative;
}

.product-list .price-row .price.new-price {
  color: var(--red);
  margin-right: 5px;
}

.product-list .price-row .old-price {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  text-decoration: line-through;
  color: var(--shade);
  white-space: nowrap;
  position: absolute;
  top: -2px;
  left: 100%;
}

.product-list .btn {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  min-width: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transform: translateY(30%);
  box-sizing: border-box;
}

.product-list .btn .added-text {
  display: none;
}

.product-list .added-item .btn {
  background: var(--white);
  color: var(--pink);
  cursor: default;
  pointer-events: none;
}

.product-list .added-item .btn .add-text {
  display: none;
}

.product-list .added-item .btn .added-text {
  display: inline-block;
  text-transform: none;
}

.product-list .added-item .btn .added-text:before {
  content: '';
  display: inline-block;
  background: url(../images/icon-check.png) no-repeat center/cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.product-list-item.product-unavailable .product-status,
.product-list-item.product-unavailable .price {
  color: var(--shade);
}

.product-list-item-holder {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
}

.product-list-item-holder a {
  margin-top: 15px;
}

.product-list-item-holder .product-rating__stars + a {
  margin-top: 0px;
}

.product-list-item-holder:hover {
  position: relative;
  z-index: 10;
}

.product-list-item-holder:hover .item-hover {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.product-list-item-holder:hover .btn {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.product-list-item-holder:hover .showPopUp .btn {
  height: auto;
}

.product-list .item-hover {
  position: absolute;
  top: -15px;
  bottom: -70px;
  right: -15px;
  left: -15px;
  visibility: hidden;
  transform: scale(.5);
  opacity: 0;
  border: 1px solid var(--dusty);
  background-color: var(--white);
  border-radius: 4px;
  z-index: -1;
}

.product-unavailable .product-list-item-holder {
  filter: grayscale(1);
}

.button-row,
.product-col .showPopUp {
  margin: 0 0 25px;
}

@media (max-width: 1199px) {
  .product-list-item {
    width: 33.33%;
  }
}

@media (max-width: 1024px) {
  .button-row {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    border-top: 1px solid var(--dusty);
    background: var(--white);
    display: flex;
    flex-direction: column-reverse;
    margin: 0 ;
  }

  .well form {
    height: 100%;
  }

  .price-block {
    display: none;
  }

  .price-add-remove {
    width: 87px;
    display: flex;
    align-items: center;
  }

  .price-sign {
    width: 29px;
    height: 29px;
    border: 1px solid rgb(174,174,174);
    opacity: 0.53;
    background: linear-gradient(var(--gray),var(--gray)), linear-gradient(var(--gray),var(--gray));
    background-position: center;
    background-size: 70% 1px, 1px 70%;
    background-repeat: no-repeat;
  }

  .price-sign.minus {
    background: linear-gradient(var(--gray),var(--gray));
    background-position: center;
    background-size: 70% 1px;
    background-repeat: no-repeat;
  }

  .price-number {
    width: 29px;
    height: 29px;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    padding: 5px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .price-number span {
    font-size: 16px;
    font-weight: normal;
    color: var(--black);
    vertical-align: middle;
  }

  .price-inbtn {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  .product-list {
    margin-left: -20px;
  }

  .product-list-item {
    font-size: 12px;
    padding: 0 0 25px 20px;
    position: relative;
    display: flex;
  }

  .product .product-list .price-row,
  .product-list .price-row {
    margin: 0;
  }

  .product-list .price-row .price {
    font-size: 20px;
  }

  .product-list-item-holder {
    justify-content: space-between;
    width: 100%;
  }

  .product-list-item-holder:hover .item-hover {
    display: none;
  }

  .product-list-item-holder:hover,
  .product-list-item-holder {
    position: static;
  }

  .product-list-item-holder:hover .btn,
  .product-list-item-holder .btn {
    visibility: visible;
    transform: translateY(0);
    opacity: 1;
    position: static;
  }

  .product-list-item-holder:hover .showPopUp .btn,
  .product-list-item-holder .showPopUp .btn {
    height: auto;
  }

  .product-list-item-holder .well {
    bottom: 30px;
    left: 20px;
    right: 0;
  }

  .product-list-item-holder .status-holder {
    margin-bottom: 4px;
  }

  .item-label-holder .heart-label {
    right: 30px;
  }
}

@media (max-width: 767px) {
  .product-list {
    padding: 0;
    margin: 0 0 0 -20px;
  }

  .product-list-item-holder .well {
    bottom: 25px;
  }

  .product-list .price-row {
    display: flex;
    flex-direction: column;
  }

  .product-unavailable .price-row {
    display: none;
  }

  .product-list .price-row .old-price {
    margin-right: 5px;
    order: 1;
  }
  .product-list .price-row .price {
    order: 2;
  }

  .product-list .product-title {
    font-size: 10px;
  }
}
