
.sales-receipt {
  max-width: 600px;
  margin: 0 auto;
}

.sales-receipt .invoice {
  font-size: 18px;
  display: block;
  margin-bottom: 20px;
}

.sales-receipt-address {
  padding-bottom: 35px;
}

.sales-receipt-address .title {
  display: block;
}

.sales-receipt .label-contact {
  display: block;
}