@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?po5653');
  src: url('../fonts/icomoon.eot?po5653#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?po5653') format('truetype'), url('../fonts/icomoon.woff?po5653') format('woff'), url('../fonts/icomoon.svg?po5653#icomoon') format('svg');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow-long-left:before {
  content: "\e90b";
}
.icon-arrow-long-right:before {
  content: "\e90c";
}
.icon-tree:before {
  content: "\e90d";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-arrow-up:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-close-add:before {
  content: "\e904";
}
.icon-ambulance:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-viber:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e909";
}
.icon-cart:before {
  content: "\e90a";
}


.icon-warning:before {
  content: "\26A0";
}
.icon-check-circle:before {
  content: "\2611"
}
.icon-remove:before {
  content: "\e5cd"
}
