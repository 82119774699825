.delivery__holder {
  border-top: 1px solid var(--dusty);
  padding: 24px 0 4px 0;
}

.delivery__holder:first-child {
  border: 0;
  padding-top: 0;
}

.delivery__holder .radio-note {
  color: var(--gray);
  margin: 0 0 0 4px;
}

.delivery__holder .radio-note a {
  color: var(--gray);
  text-decoration: underline;
}

.delivery__holder .radio-note a:hover {
  text-decoration: none;
}

.delivery__holder .radio .jcf-radio,
.delivery__holder .checkbox .jcf-checkbox {
  margin: 0 10px 0 0;
}

.delivery__title {
  display: block;
  margin: 0 0 30px;
}

.delivery__item {
  margin: 0 0 0 -9px;
}

.delivery__item.disabled {
  color: var(--gray);
  opacity: 0.7;
}

.delivery__item .input-holder:after {
  display: none;
}

.delivery__item .input-holder {
  display: flex;
  justify-content: space-between;
}

.billing-form .delivery__item .input-holder.three-in-row .input-item:first-child {
  width: 50%;
}

.billing-form .delivery__item .input-holder.three-in-row .input-item + .input-item {
  width: 22%;
}

.delivery__item .switch {
  display: none;
}

.delivery__drop {
  display: none;
  padding: 0 0 11px 46px;
  margin: -11px 0 0;
}

.delivery__item.active .switch {
  display: block;
}

.delivery__item.active .delivery__drop {
  display: block;
}

.autocomplete__drop {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s linear;
  
  &.active{
    visibility: visible;
    opacity: 1;
  }
}
.autocomplete__list {
  max-height: 160px;
  border-radius: 6px;
  border: 1px solid var(--silver);
  background: var(--white);
  overflow: auto;

  li {
    padding: 0;
    &:before {
      display: none;
    }
  }
  
  a {
    padding: 3px 10px;
    display: block;
    text-decoration: none;

    &:hover {
      background: var(--silver);
    }
  }

  span {
    padding: 3px 10px;
    display: block;
  }
}

@media (max-width: 767px) {
  .billing-form .delivery__item .input-holder.three-in-row {
    flex-wrap: wrap;
    padding-left: 0;
    //margin-left: -20px;
    width: auto;
  }

  .delivery__item {
    margin: 0;
  }

  .billing-form .delivery__item .input-holder.three-in-row .input-item {
    min-width: 50%;
    padding: 20px 0 0 20px;
  }

  .billing-form .delivery__item .input-holder.three-in-row .input-item:first-child {
    padding-top: 0;
    min-width: 100%;
  }
}