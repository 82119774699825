.tooltip-holder {
  position: relative;
  padding: 20px 0;
}

.tooltip-holder.active .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip-opener {
  position: relative;
  cursor: pointer;
}

.tooltip-opener:hover {
  color: var(--pink);
}

.tooltip {
  position: absolute;
  top: 50%;
  right: 100%;
  width: 450px;
  border: 1px solid var(--pink);
  padding: 20px;
  transition: all .3s ease;
  visibility: hidden;
  opacity: 0;
  background: var(--white);
  transform: translateY(-50%);
  margin: 0 20px 0 0;
}

.tooltip:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  border-style: solid;
  margin: -10px 0 0;
  border-width: 10px;
  border-color: transparent transparent transparent var(--pink);
}

.tooltip p {
  margin: 0;
}


@media (min-width: 1025px) {
  .tooltip-opener:hover + .tooltip {
    visibility: visible;
    opacity: 1;
  }
}


@media (max-width: 1024px) {
  .tooltip-holder.active .tooltip {
    position: relative;
  }

  .tooltip {
    transform: translateY(0);
    top: 100%;
    left: 0;
    right: auto;
    width: 100%;
    margin: 20px 0 0;
  }

  .tooltip:after {
    display: none;
  }

  .delivery-block-item {
    margin-right: 20px;
  }
}

