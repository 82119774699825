/* pagination */
.pagination-holder {
  overflow: hidden;
  text-align: center;
  /*padding-bottom: 50px;*/
}

.pagination-holder:last-child {
  padding-bottom: 0;
}

.pagination-holder .load-more-btn {
  margin-bottom: 30px;
}

.pagination {
  list-style: none;
  padding: 5px 0 40px;
  margin: 0 0 0 40px;
  font-size: 14px;
  line-height: 27px;
  float: right;
}


.pagination li {
  display: inline-block;
  border: 1px solid var(--silver);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 5px;
  padding: 0;
}

.pagination li:before {
  display: none;
}

.pagination li:hover a,
.pagination li.active a {
  background: var(--dark-gray);
  color: var(--white);
}

.pagination li.active a {
  font-weight: 500;
}

.pagination li a {
  display: block;
  padding: 0 3px;
  text-align: center;
  text-decoration: none;
  height: 25px;
  min-width: 25px;
  font-size: 14px;
  line-height: 25px;
}

.pagination li.first *,
.pagination li.last *,
.pagination li.prev *,
.pagination li.next * {
  font-size: 19px;
  line-height: 23px;
}

@media (max-width: 767px) {
  .pagination {
    display: block;
    text-align: center;
    float: none;
    margin: 0;
  }

  .btn-inform {
    width: 100%;
  }

  .pagination li {
    margin-top: 10px;
  }
}