.product-modifications {
  margin: 0 0 25px;

  select {
    min-width: 0;
    width: 100%;
  }

  .jcf-select .jcf-select-drop {
    z-index: 100;
  }
}

.product-modifications__title {
  display: block;
  font-weight: 600;
  margin: 0 0 10px;
}

.modifications-gallery {
  margin: 0 0 25px;

  .swiper-slide {
    width: 80px;
    height: 80px;
    padding: 1px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border: 2px solid #ccc;
      pointer-events: none;
    }
  }

  .swiper-slide.active:after,
  .swiper-slide.swiper-slide-active.active:after,
  .swiper-slide:hover:after,
  .swiper-slide.swiper-slide-active:hover:after {
    border-color: #f0719d;
  }

  .swiper-slide.slide-unactive:after {
    border: 2px dashed #c0c0c0;
    background: rgba(255, 255, 255, 0.65);
  }

  .swiper-slide.slide-unactive:hover:after {
    border: 2px solid #ccc;
  }

  .swiper-button-prev.swiper-button-lock,
  .swiper-button-next.swiper-button-lock,
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none !important;
  }

  &.swiper-initialized .swiper-button-prev,
  &.swiper-initialized .swiper-button-next {
    display: block;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 0;
    margin: 0;
    z-index: 5;
    width: 30px;
    height: 80px;
    opacity: 0.8;
    box-shadow: 0 2px 7px var(--smoke);
    background-color: rgba(255, 255, 255, 0.9);
    transition: opacity .1s linear;
    display: none;

    &:hover {
      opacity: 1;
    }

    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      left: 9px;
      top: 50%;
      margin-top: -7px;
      border-style: solid;
      border-color: var(--pink);
      border-width: 3px 3px 0 0;
      transform: rotate(-135deg);
    }
  }

  .swiper-button-next:after {
    left: auto;
    right: 9px;
    transform: rotate(45deg);
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}
