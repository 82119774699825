.related-products-title {
  margin-bottom: 40px;
}

.related-products {
  padding-top: 50px;

  .product-list {
    margin-left: 0;

    @media (max-width: 767px) {
      margin: 0 -10px;
    }

    .product-list-item {
      padding: 20px 20px 70px;
      min-width: 140px;
      width: auto;

      @media (max-width: 767px) {
        padding: 0 10px 25px;
        width: 180px;
        min-width: 0;
      }
    }
  }
}

@media (max-width: 1023px) {
  .related-products {
    padding-top: 25px;
  }

  .product .related-products {
    padding-left: 0;
  }

  .related-products-title {
    margin-bottom: 20px;
  }
}
