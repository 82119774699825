.blog-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &__title {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 768px;
    width: 100%;

    @media (min-width: 1024px) {
      max-width: 1024px;
    }
  }

  &__item {
    padding: 0;

    article {
      position: relative;
      box-sizing: border-box;
      position: relative;
      display: flex;
      gap: 10px;
      padding: 10px;

      @media (min-width: 480px) {
        gap: 16px;
        padding: 16px;
      }

      @media (min-width: 1024px) {
        gap: 32px;
        padding: 32px;
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 4px;
        border: 1px solid var(--dusty);
        opacity: 0;
        transform: scale(0.98);
        transition: transform 0.3s, opacity 0.3s;
      }

      &:hover::before {
        opacity: 1;
        transform: scale(1);
      }
    }

    &::before {
      display: none;
    }
  }

  &__item-image {
    width: 80px;
    object-fit: contain;
    object-position: top;

    @media (min-width: 480px) {
      width: 150px;
    }

    @media (min-width: 1024px) {
      width: 240px;
    }
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (min-width: 1024px) {
      gap: 16px;
    }

    & > * {
      margin: 0;
    }
  }

  &__item-title {
    font-size: 14px;

    @media (min-width: 1024px) {
      font-size: 20px;
    }

    a {
      text-decoration: none;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: 1;
      }

      &:hover {
        color: var(--pink);
      }
    }
  }

  &__item-author {
    font-size: 12px;
    color: gray;

    @media (min-width: 1024px) {
      font-size: 14px;
    }

    * {
      color: gray;
    }

    span {
      font-style: italic;
    }

    a {
      position: relative;
      z-index: 1;
    }
  }

  &__item-description {
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: 12px;

    @media (min-width: 1024px) {
      font-size: 14px;
    }

    a {
      color: var(--pink);
    }

    @media (min-width: 480px) {
      -webkit-line-clamp: 3;
    }

    @media (min-width: 1024px) {
      -webkit-line-clamp: 5;
    }
  }
}
