.jcf-scrollbar {
  width: 8px;
  border-radius: 4px;
  background: var(--smoke);
  right: 0;
  top: 0;
}

.jcf-scrollbar-handle {
  width: 8px;
  background: var(--dark-gray);
  border-radius: 4px;
}
.radio-list li,
.check-list li {
  padding-left: 0;
}

.radio-list li:before,
.check-list li:before {
  display: none;
}

.radio-list.inline,
.check-list.inline {
  display: flex;
}

.radio-list li + li,
.check-list li + li {
  margin-top: 10px;
}

.radio-list.inline li + li,
.check-list.inline li + li {
  margin-top: 0;
  margin-left: 30px;
}

.check-list .num {
  color: var(--dark-gray);
}

.radio,
.checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.radio .jcf-radio,
.radio input[type="radio"],
.checkbox .jcf-checkbox,
.checkbox input[type="checkbox"] {
  margin: 0 15px 0 0;
}

.radio .jcf-radio,
.checkbox .jcf-checkbox {
  background-color: var(--smoke);
  min-width: 16px;
  width: 16px;
  height: 16px;
  position: relative;
}

.radio .jcf-radio {
  border-radius: 50%;
}

.radio .jcf-radio.jcf-checked,
.checkbox .jcf-checkbox.jcf-checked {
  background-color: var(--pink);
}

.radio .jcf-radio.jcf-checked:before,
.checkbox .jcf-checkbox.jcf-checked:before {
  opacity: 1;
}

.checkbox .jcf-checkbox:before {
  content: "";
  width: 12px;
  height: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  border: solid var(--white);
  border-width: 0 0 2px 2px;
  opacity: 0;
  transform: rotate(-45deg);
  margin: -4px 0 0 -6px;
}

.radio .jcf-radio:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  background: var(--white);
  border-radius: 50%;
  margin: -3px 0 0 -3px;
  opacity: 0;
}

/* select */
.jcf-select {
	display: block;
	position: relative;
	background: var(--white);
  border: 1px solid var(--dusty);
  min-width: 62px;
  border-radius: 4px;
  height: 40px;
  padding: 8px 26px 7px 10px;
}
.jcf-select select {
  z-index: 1;
	left: 0;
	top: 0;
}
.jcf-select .jcf-select-text {
  text-overflow:ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: default;
	display: block;
  font-size: 14px;
  line-height: 24px;
}

.jcf-select .jcf-select-opener {
	position: absolute;
	text-align: center;
	width: 26px;
	bottom: 0;
	right: 0;
	top: 0;

  &:after {
    content: "";
    position: absolute;
    top: 16px;
    right: 9px;
    width: 6px;
    height: 6px;
    border-bottom: 1px solid var(--dark-gray);
    border-right: 1px solid var(--dark-gray);
    pointer-events: none;
    transition: all 0.4s ease;
    transform: rotate(45deg);
  }
}

.jcf-select.jcf-drop-active .jcf-select-opener {
  &:after {
    top: 18px;
    transform: rotate(-135deg);
  }
}

body > .jcf-select-drop {
	position: absolute;
	margin: -1px 0 0;
	z-index: 9999;
}
body > .jcf-select-drop.jcf-drop-flipped {
	margin: 1px 0 0;
}
.jcf-select .jcf-select-drop {
	position: absolute;
	margin-top: 0px;
	z-index: 9999;
	top: 100%;
	left: -1px;
	right: -1px;
}
.jcf-select .jcf-drop-flipped {
	bottom: 100%;
	top: auto;
}
.jcf-select.jcf-compact-multiple {
	max-width: 220px;
}
.jcf-select.jcf-compact-multiple .jcf-select-opener:before {
	display: inline-block;
	padding-top: 2px;
	content: '...';
}
.jcf-select-drop .jcf-select-drop-content {
	border: 1px solid var(--dusty);
  background: var(--white);
  border-radius: 4px;
}
.jcf-select-drop.jcf-compact-multiple .jcf-hover {
	background: none;
}
.jcf-select-drop.jcf-compact-multiple .jcf-selected {
	background: var(--dark-smoke);
	color: var(--black);
}
.jcf-select-drop.jcf-compact-multiple .jcf-selected:before {
	display: inline-block;
	content: '';
	height:4px;
	width:8px;
	margin:-7px 5px 0 -3px;
	border:3px solid var(--dark-gray);
	border-width:0 0 3px 3px;
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')";
}

.jcf-select .jcf-select-drop {
  li {
    padding: 0;
    &:before {
      display: none;
    }
  }
  .jcf-option {
    padding: 3px 10px;
    display: block;
    cursor: pointer;

    &:hover {
      background: var(--silver);
    }
    
    &.jcf-disabled {
      opacity: 0.5;
      color: var(--black);
      pointer-events: none;
      appearance: none;
      position: absolute !important;
      left: -9999px !important;
      height: 1px !important;
      width: 1px !important;
    }
  }
}
.jcf-select .jcf-list-content {
  display: block;
}