
.loader {
  border: 6px solid var(--smoke);
  border-top: 6px solid var(--pink);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-left: 10px;
}

.loader {
  border: 16px solid var(--smoke);
  border-radius: 50%;
  border-top: 16px solid var(--pink);
  width: 120px;
  position: center;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}