/* Common site styles */
html {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  max-height: 1000000px;
}


body {
  color: var(--text-color);
  background: var(--bkg-color);
  font: 14px/1.43 "Roboto", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

body.nav-active {
  overflow: hidden;
  height: 100%;
}

body.nav-active #wrapper {
  overflow: hidden;
  height: 100%;
}

.w1 {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.mobile-visible {
  display: none !important;
}

.tablet-visible {
  display: none !important;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

.container {
  max-width: 1420px;
  padding: 30px 20px;
  margin: 0 auto;
}

.text-content {
  max-width: 840px;
  margin: 0 auto;
}

hr {
  height: 0;
  border: solid var(--black);
  border-width: 0 0 1px;
  margin: 0 0 35px;
}

.heading {
  text-align: center;
  margin-bottom: 25px;
}

.heading h1,
.heading .h1,
.heading h2,
.heading .h2,
.heading h3,
.heading .h3,
.heading h4,
.heading .h4,
.heading h5,
.heading .h5,
.heading h6,
.heading .h6 {
  margin: 0;
}

.main-holder {
  display: -ms-flexbox;
  display: flex;
}


#sidebar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 290px;
  width: 290px;
  padding-right: 35px;
  order: 1;
}

#content {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  order: 2;
  max-width: calc(100% - 290px);

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

#search-content {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    order: 2;
    max-width: 100%;
}

.radio-list,
.check-list,
#header .left-col .social-networks,
.main-nav__list,
.main-nav .drop ul,
#footer ul,
.tabset,
.brands-list,
.top-nav ul,
.main-banner-group ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.btn,
.main-nav__list > li:hover .drop,
#footer a,
.product-list .item-hover,
.product-list .product-title a,
.brands-list a {
  transition: all .2s ease-in-out;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.gm-style img {
  max-width: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  display: block;
  font-family: inherit;
  font-weight: 400;
  margin: 0 0 .5em;
  color: inherit;
}

h1,
.h1 {
  font-size: 28px;
}

h2,
.h2 {
  font-size: 25px;
}

h3,
.h3 {
  font-size: 21px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 14px;
}

p {
  margin: 0 0 1em;
}

a {
  color: var(--black);
}

a:hover,
a:focus {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul li {
  position: relative;
  padding: 0 0 10px 15px;
}

ul li:before {
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  background: var(--black);
  width: 5px;
  height: 5px;
  border-radius: 50%;
}



form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none;
  box-sizing: border-box;
  border: 1px solid var(--dusty);
  padding: 8px 10px 7px;
  line-height: 24px;
  min-width: 62px;
  border-radius: 4px;
  height: 40px;
  caret-color: var(--pink);
  outline: none;
}

input:focus {
  outline: none;
}

input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.5;
  color: var(--black);
}

input[type="text"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 0.5;
  color: var(--black);
}

input[type="text"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 0.5;
  color: var(--black);
}

input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 0.5;
  color: var(--black);
}

input[type="text"].placeholder,
input[type="tel"].placeholder,
input[type="email"].placeholder,
input[type="search"].placeholder,
input[type="password"].placeholder,
input[type="number"].placeholder,
textarea.placeholder {
  opacity: 0.5;
  color: var(--black);
}

select {
  border: 1px solid var(--dusty);
  min-width: 62px;
  border-radius: 4px;
  padding: .5em .7em;
  height: 40px;
  font-size: 16px;
}

textarea {
  resize: vertical;
  vertical-align: top;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"] {
  -webkit-appearance: none;
  cursor: pointer;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}


html body.st-body-no-scroll {
  position: static;
  overflow-y: auto;
}

.discount-label {
  width: 24px;
  height: 24px;
  background-color: var(--red);
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-label {
  height: 24px;
  background: var(--green);
  margin-right: 5px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 4px 2px;
  box-sizing: border-box;
}

.new-label {
  width: 46px;
  height: 24px;
  margin-right: 5px;
  background-color: var(--turquoise);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
}

.item-label-holder .heart-label {
  position: absolute;
  right: 10px;
  top: 10px;
}

.item-label-holder .heart-label .heart-label-white {
  display: block;
}

.item-label-holder .heart-label .heart-label-pink {
  display: none;
}

.scroll-container {
  height: calc(100%);
  overflow-y: scroll;
}

.scroll-hidden {
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.relative {
  position: relative;
}

.price-col {
  white-space: pre;
}

.text-center {
  text-align: center;
}


.alert-info {
  margin: 0 0 40px;
}

.alert-info div {
  margin: 0 0 60px;
}

.not-available {
  background: var(--smoke);
  padding: 15px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  line-height: 25px;
  margin: -15px 0 50px 0;
}

.not-available .try-to-find {
  margin: 8px 0 0 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.red-color {
  color: var(--red);
}

label.error {
  color: var(--error);
  position: absolute;
  left: 0;
  top: 46px;
}

@media (max-width: 1100px) {
  .tablet-hidden {
    display: none !important;
  }

  .tablet-visible {
    display: block !important;
  }

  .brands__content {
    column-count: 3;
  }
}

@media (min-width: 767px) {
  .text-article {
    visibility: hidden;
  }

  .text-count {
    visibility: hidden;
  }
}

@media (max-width: 767px) {
  .text-article {
    visibility: visible;
  }

  .text-count {
    visibility: visible;
  }
  h1,
  .h1 {
    font-size: calc(28px * .7);
  }

  h2,
  .h2 {
    font-size: calc(25px * .7);
  }

  h3,
  .h3 {
    font-size: calc(21px * .7);
  }

  h4,
  .h4 {
    font-size: calc(18px * .7);
  }

  h5,
  .h5 {
    font-size: calc(16px * .7);
  }

  h6,
  .h6 {
    font-size: calc(14px * .7);
  }

  .mobile-hidden {
    display: none !important;
  }

  .mobile-visible {
    display: block !important;
  }

  .container {
    padding: 10px 10px 20px;
  }

  hr {
    margin: 0 0 17px;
  }

  .heading {
    margin-bottom: 10px;
  }

  .heading.no-margin {
    margin-bottom: 0;
  }

  .main-holder {
    display: block;
  }
  .alert-info {
    margin: 0 0 10px;
  }

  .alert-info div {
    margin: 0 0 30px;
  }

  .promo-top-margin {
    margin-top: 0;
  }

  .not-available {
    padding: 10px;
    margin: 10px 0 100px 0;
  }
}



@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus, textarea:focus, input:focus {
    font-size: 16px;
  }
}

.item-image .top-sales-product {
  display: block;
}