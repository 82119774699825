.mobile-search-form {
  border-bottom: 1px solid var(--dusty);
  padding: 10px 65px 10px 10px;
  display: flex;
  position: relative;
}

.mobile-search-form input[type="search"],
.search-form.search-form--mobile input[type="search"] {
  display: inline-block;
  width: 100%;
  border: 1px solid var(--dusty);
  padding: 0 10px 0 40px;
  line-height: 40px;
  outline: none;
  height: auto;
  background: var(--smoke);
  font-size: 16px;
}

.mobile-search-form.active input,
.search-form.search-form--mobile.active input{
  border-color: var(--pink);
  background: var(--white);
}

.mobile-search-form input[type="search"]::-webkit-search-cancel-button,
.search-form.search-form--mobile input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 20px;
}

.mobile-search-form .reset,
.search-form.search-form--mobile .reset {
  border: none;
  background: none;
  font-size: 20px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 21px;
  right: 22px;
  z-index: 2;
  outline: none;
}

.mobile-search-form .reset:after,
.mobile-search-form .reset:before,
.search-form.search-form--mobile .reset:after,
.search-form.search-form--mobile .reset:before {
  content: "";
  position: absolute;
  top: 50%;
  background: var(--black);
  height: 2px;
  width: 20px;
  left: 0;
}

.mobile-search-form .reset:after,
.search-form.search-form--mobile .reset:after {
  transform: rotate(45deg);
}

.mobile-search-form .reset:before,
.search-form.search-form--mobile .reset:before {
  transform: rotate(-45deg);
}

.mobile-search-form .submit,
.search-form.search-form--mobile .submit {
  position: absolute;
  padding: 3px 0 0 12px;
  border: none;
  background: none;
  font-size: 22px;
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.mobile-search-form .submit i,
.search-form.search-form--mobile .submit i {
  line-height: 40px;
}

.mobile-search-form input[type="reset"],
.search-form.search-form--mobile input[type="reset"] {
  border: none;
  background: none;
  text-transform: uppercase;
}

.mobile-search-form .search-clear,
.search-form.search-form--mobile .search-clear {
  position: absolute;
  right: 50px;
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  width: 40px;
  height: 40px;
}

/* desktop product search form */

.mobile-hidden .search-form {
  margin: 10px 0 20px;
  max-width: 100%;
  display: block;
}



.product-search-form button[type="submit"] i {
  line-height: 40px;
}

/* desktop search form */
.search-form {
  border: 1px solid var(--dusty);
  border-radius: 4px;
  width: 310px;
  position: relative;
}

.search-form.active {
  border-color: var(--pink);
}

.search-form button[type="submit"] {
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  width: 40px;
  height: 40px;
}

.search-form button[type="submit"] i {
  line-height: 40px;
}

.search-form input[type="search"] {
  width: 100%;
  border: none;
  padding: 0 10px 0 40px;
  line-height: 40px;
  outline: none;
  height: auto;
  background: var(--smoke);
}

.search-form.active input {
  background: var(--white);
}

.mobile-search-form input[type="search"]::-webkit-search-cancel-button,
.search-form input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: url(../images/inputVector.png);
  cursor: pointer;
}

.product-search-form.active input {
  background: var(--white);
}

.search-form .search-clear {
  position: absolute;
  top: 0;
  left: 210px;
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
  width: 30px;
  height: 30px;
}

.search-list {
  display: flex;
  flex-direction: column;
  align-content: center;
  background: var(--white);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 103;
  margin-top: 1px;
  box-shadow: 0 5px 25px rgb(84 104 129 / 20%);
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
}

.search-list-item {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid var(--silver);
  overflow: hidden;
}

.search-list-item {
  text-decoration: none;
}

.search-list-item-name {
  transition: color 0.15s linear;
}

.search-list-item:hover .search-list-item-name {
  color: var(--pink);
}

.search-list-item-description {
  flex: 1 1;
  text-align: left;
}


.search-list-item-img {
  margin-right: 10px;
  width: 70px;
  height: 70px;
  position: relative;
}

.search-list-item-price {
  font-weight: bold;
}

.search-list-item-price .price.new-price {
  color: var(--pink);
}

.search-list-item-price .old-price {
  color: var(--shade);
  text-decoration: line-through;
}

.search-list-show-more a {
  display: block;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  color: var(--pink);
}

.search-list-show-more a:hover {
  text-decoration: underline;
}

.search-list-item-name + .search-list-item-price {
  padding-top: 10px;
}