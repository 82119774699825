
/* summary-table */
.summary-table {
  width: 100%;
}

.summary-table th {
  text-align: left;
}

.table-striped th {
  white-space: nowrap;
}

.summary-table th,
.summary-table td {
  padding: 15px 6px;
}

.summary-table th:first-child,
.summary-table tbody td:first-child {
  padding-left: 0;
}

.summary-table tfoot tr {
  background: var(--smoke);
}

.summary-table .item-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.summary-table .item-title a {
  text-decoration: none;
}

.summary-table .item-title a:hover {
  text-decoration: underline;
}

.summary-table .total-title {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.summary-table .mobile-price .price,
.summary-table .mobile-price .old-price,
.summary-table .mobile-item-amount {
  white-space: nowrap;
}

.summary-table {
  .item-amount {
    .price-row {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }

    .desktop-price {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .discount-description {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.summary-table .total-value {
  font-size: 21px;
  font-weight: 500;
  text-align: right;
}

.summary-table .total-value span {
  white-space: nowrap;
}

.summary-table .summary-info:first-child .total-title,
.summary-table .summary-info:first-child .total-value {
  padding-top: 15px;
}

.summary-table .summary-info .total-title,
.summary-table .summary-info .total-value {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  padding: 5px 15px;
}

.summary-table .summary-info:last-child .total-title,
.summary-table .summary-info:last-child .total-value {
  font-size: 18px;
  font-weight: 500;
}

.summary-table .summary-info:last-child .total-value {
  font-weight: bold;
  font-size: 24px;
}

.summary-table .summary-info:last-child .total-title {
  line-height: 34px;
}

.summary-table .total-value .val {
  font-size: 14px;
}

.summary-table input[type="number"] {
  max-width: 80px;
}

.summary-table .item-amount,
.summary-table .item-price {
  white-space: nowrap;
}

.summary-table .item-price sub {
  bottom: 0;
  font-size: 14px;
  font-weight: 500;
}

.summary-table {
  .price {
    margin-right: 8px;
    font-size: 14px;
    font-weight: 500;

    sub {
      bottom: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .price.new-price {
    color: var(--red);
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;

    sub {
      bottom: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }

  @media (max-width: 767px) {
    .price {
      font-size: 20px;
    }
  }

  @media (max-width: 767px) {
    .price.new-price {
      font-size: 20px;
    }
  }

  .old-price {
    font-size: 12px;
    font-weight: 500;
    text-decoration: line-through;
    color: var(--shade);
    white-space: nowrap;
    line-height: 28px;

    sub {
      font-size: 12px;
      font-weight: 500;
      bottom: 0;
    }

    .total-old-price {
      margin-right: 8px;
    }
  }

  @media (max-width: 767px) {
    .old-price {
      display: block;
      margin: -21px 8px 0 0;
    }
  }
}

.summary-table .item-price .price-row.checkout {
  display: flex;
  flex-direction: column;
}

.summary-table .item-image .image-holder {
  position: relative;
}

.summary-table .item-image {
  width: 70px;
  height: 60px;
}

.summary-table .item-image img {
  display: block;
  height: auto;
}

.summary-table .vendor-code {
  color: var(--dark-gray);

  span {
    display: none;
  }
}

.summary-table .mobile-vendor-code {
  span {
    display: none;
  }
}

.summary-table .remove {
  text-decoration: none;
  font-size: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  margin-top: 3px;
}

.summary-table .total-quantity span {
  color: var(--dark-gray);
}

.summary-table .total-remove-all a {
  color: var(--dark-gray);
  text-transform: uppercase;
  text-decoration: none;
  float: right;
}

.summary-table tbody tr {
  border-top: 1px solid var(--dusty);
}

.summary-table .item-label-holder {
  right: 0;
  flex-wrap: wrap;
  width: auto;
  left: 0;
  margin-left: -2px;
}

.item-label-inner {
  display: flex;
  max-width: 100%;
  flex-grow: 1;
}

.summary-table .item-image .discount-label {
  margin: 2px 0 0 2px;
  height: 18px;
  flex: 0 0 18px;
}

.summary-table .item-image .success-label {
  margin: 2px 0 0 2px;
  height: 18px;
  font-size: 12px;
  padding: 0 4px;
  flex: 1 0 calc(50% - 2px);
  width: calc(50% - 2px);
}

.summary-table .item-image .new-label {
  margin: 2px 0 0 2px;
  height: 18px;
  font-size: 12px;
  padding: 0 4px;
  flex: 1 0 calc(50% - 2px);
  width: calc(50% - 2px);
}

.summary-table .item-amount {
  font-size: 20px;
  line-height: 24px;
}


.summary-table .item-title .promotion-description {
  color: var(--red);
  font-size: 12px;
  padding: 0;
  border: none;
  text-transform: none;
}

.summary-table {
  .item-number {
    span {
      display: none;
    }
  }
}

.summary-table {
  .mobile-item-number {
    span {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .summary-table .summary-info,
  .summary-table tfoot .summary-info {
    padding: 0 5px;
  }

  .summary-table .summary-info .total-title,
  .summary-table .summary-info .total-value {
     padding: 5px;
    //padding: 10px 5px;
  }

  .summary-table .summary-info:first-child .total-title,
  .summary-table .summary-info:first-child .total-value {
    padding-top: 10px;
  }

  // .summary-table .summary-info:last-child .total-title,
  // .summary-table .summary-info:last-child .total-value {
  //   padding: 0;
  // }

  // .summary-table .summary-info:only-child .total-title,
  // .summary-table .summary-info:only-child .total-value {
  //   padding: 0;
  // }

  .summary-table {
    position: relative;
  }

  .summary-table,
  .summary-table tbody {
    display: block;
  }

  .summary-table.table-striped thead {
    display: none;
  }

  .summary-table.table-striped th:first-child, .summary-table.table-striped tbody td:first-child {
    padding: 0 10px 10px 0;
  }

  .summary-table.table-striped .mobile-vendor-code,
  .summary-table.table-striped .vendor-code {
    color: var(--dark-gray);
  }

  .summary-table.table-striped .mobile-item-title,
  .summary-table.table-striped .item-title {
    padding: 0 0 10px;
  }

  .summary-table.table-striped .mobile-item-amount,
  .summary-table.table-striped .item-amount {
    padding: 5px 0 0;
  }

  .summary-table.table-striped .mobile-item-number,
  .summary-table.table-striped .item-number {
    border-radius: 3px;
    padding: 4px 4px 4px 0;
    min-width: 60px;
  }

  .summary-table tbody tr {
    display: block;
    overflow: hidden;
    position: relative;
    padding: 10px 10px 10px 70px;
  }

  .summary-table tfoot {
    display: flex;
    flex-wrap: wrap;
  }

  .summary-table tfoot tr {
    display: flex;
    flex: 1 0 100%;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px;
  }

  .summary-table thead tr.column-names {
    display: none;
  }

  .summary-table tbody td {
    display: block;
    padding: 0;
  }

  .summary-table .mobile-item-image,
  .summary-table .item-image {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    padding-right: 10px;
  }

  .summary-table .item-image {
    top: 10px;
  }

  .summary-table .mobile-item-title,
  .summary-table .item-title {
    padding: 0 30px 2px 0;
    overflow: hidden;
  }

  .summary-table .mobile-price {
    display: none;
  }

  .summary-table .vendor-code {
    font-size: 13px;
    padding-bottom: 5px;
    float: left;
    clear: left;

    span {
      display: inline;
    }
  }

  .summary-table .mobile-vendor-code {
    span {
      display: inline;
    }
  }

  .summary-table .item-price {
    display: none;
  }

  .summary-table {
    .item-number {
      span {
        display: inline;
      }
    }
  }

  .summary-table {
    .mobile-item-number {
      span {
        display: inline;
      }
    }
  }

  .summary-table .mobile-item-number,
  .summary-table .item-number {
    float: left;
    clear: left;
    padding-left: 0;
  }

  .summary-table .mobile-item-amount,
  .summary-table .item-amount {
    float: right;
    font-weight: 500;
    padding-top: 5px;
  }

  .summary-table .discount-description {
    display: inline-block;
  }

  .summary-table .discount-description .promotion-description {
    padding: 0;
    margin-top: 10px;
    font-size: 12px;
  }

  .summary-table .mobile-total-title,
  .summary-table .summary-info .total-title {
    font-size: 14px;
    line-height: 22px;
  }

  .summary-table .mobile-item-title a {
    text-decoration: none;
  }

  .summary-table .remove {
    position: absolute;
    top: 5px;
    right: 0;
  }

  .summary-table input[type="number"] {
    height: 30px;
  }

  .summary-table .total-quantity {
    float: left;
  }

  .summary-table .total-remove-all {
    float: right;
  }
}