.instagram-swiper {
  overflow: hidden;
  padding-bottom: 30px;
}

.instagram-swiper-icon-holder {
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.instagram-swiper-image {
  max-height: 250px;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.instagram-swiper .swiper-pagination {
  position: static;
}

.instagram-swiper .swiper-wrapper {
  margin-bottom: 35px;
}

.instagram-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 4px;
}

.instagram-swiper  .swiper-pagination-bullet-active {
  background: var(--pink);
}

@media (max-width: 1024px) {
  .instagram-swiper {
    padding: 5px 0 30px;
  }
}

@media (max-width: 767px) {
  .instagram-swiper {
    padding-bottom: 15px;
  }

  .product-col-description {
    padding-top: 30px;
  }

  .categories .related-products {
    padding-top: 0;
  }

  .price-mobile-btn {
    width: 33%;
  }
}
