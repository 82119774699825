.product-gallery-holder {
  display: flex;

  @media (max-width: 1200px) {
    height: 350px;
  }

  @media (max-width: 768px) {
    height: 320px;
  }
}

.product-gallery-pagination.swiper {
  margin-right: 30px;
  padding: 20px 0;
}
.product-gallery-pagination {
  flex: 0 0 62px;
  width: 62px;

  @media (max-width: 768px) {
    display: none !important;
  }

  .swiper-slide {
    height: 62px !important;
    cursor: pointer;
    padding: 1px;
    box-sizing: border-box;

    &:after {
      content: "";
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      border: 1px solid rgba(0,0,0,0);
    }

    &:hover:after,
    &.swiper-slide-thumb-active:after {
      border-color: var(--pink);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 62px;
    height: 20px;
    z-index: 2;
    background: var(--white);
    overflow: hidden;
    text-indent: -9999px;
    top: 0;
    left: 0;
    margin: 0;
    box-sizing: border-box;

    &.swiper-button-disabled {
      opacity: 0;
      cursor: default;
    }
    &.swiper-button-disabled:after {
      border-color: var(--smoke);
    }

    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      left: 50%;
      top: 50%;
      margin: -2px 0 0 -4px;
      border-style: solid;
      border-color: var(--pink);
      border-width: 2px 2px 0 0;
      transform: rotate(-45deg);
      transition: border-color .15s linear;
    }
  }

  .swiper-button-next {
    top: auto;
    bottom: 0;
  }
  .swiper-button-next:after {
    margin-top: -5px;
    transform: rotate(135deg);
  }

  .play-btn {
    background: var(--error);
    border-radius: 4px;
    height: 16px;
    width: 22px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    &:after {
      content: ' ';
      top: 50%;
      left: 50%;
      position: absolute;
      margin: -3px 0 0 -2px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3px 0 3px 6px;
      border-color: transparent transparent transparent var(--white);
    }
  }

}
.product-gallery-pagination,
.product-gallery {
  picture {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
  }
}

.product-gallery-pagination img {
  object-fit: cover;
}

.product-gallery img {
  object-fit: contain;
}

.product-gallery.swiper {
  @media (max-width: 768px) {
    padding-bottom: 25px;
  }
}
.product-gallery {
  height: 450px;

  @media (max-width: 1200px) {
    height: auto;
  }

  .slide-video {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slide-video-holder {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .swiper-slide,
  .swiper-slide-prev {
    opacity: 0 !important;
  }

  .swiper-slide-active {
    opacity: 1 !important;
  }

  .swiper-button-prev.swiper-button-lock,
  .swiper-button-next.swiper-button-lock {
    display: none !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    width: 50px;
    height: 50px;
    opacity: 0.8;
    transition: opacity .1s linear;
    display: none;

    &:hover {
      opacity: 1;
    }

    @media (max-width: 768px) {
      display: block;    
    }

    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      left: 50%;
      top: 50%;
      margin: -7px 0 0 -4px;
      border-style: solid;
      border-color: var(--pink);
      border-width: 3px 3px 0 0;
      transform: rotate(-135deg);
    }
  }

  .swiper-button-next:after {
    margin-left: -11px;
    transform: rotate(45deg);
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  .swiper-pagination {
    justify-content: center;
    display: none;
    
    @media (max-width: 768px) {
      display: flex;      
    }
  }
  .swiper-pagination-bullet {
    margin: 0 5px;
    background: var(--dusty);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: 1;
    transition: background .1s linear;
  }

  .swiper-pagination-bullet-active {
    background: var(--pink);
  }
}
