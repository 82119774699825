.promotion-description-holder-desktop {
  display: flex;
  padding: 0 0 20px 30px;

  @media (max-width: 1199px) {
    display: block;
    padding: 0 0 20px;
  }

  @media (max-width: 1100px) {
    display: none;
  }
}

.promotion-description-holder-mobile {
  display: none;

  @media (max-width: 1100px) {
    display: block;
    margin-bottom: 20px;
  }
}

.promotion-description {
  padding: 10px;
  border: 1px solid var(--red);
  border-radius: 4px;
  color: var(--red);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.promotion-description.desktop {
  @media (max-width: 767px) {
    display: none;
  }
}

.promotion-description.mobile {
  display: none;

  @media (max-width: 767px) {
    display: block;
    border: none;
  }
}

.promotion-description p {
  display: inline;
  margin: 0;
}
