/* checkout styles */
.checkout {
  .main-nav__list,
  .top-banner,
  .top-nav,
  .nav-opener,
  .search-opener,
  .search-form-holder,
  #header .left-col .social-networks,
  #header .right-col {
    display: none !important;
  }
}

.billing-form {
  textarea,
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"],
  input[type="number"],
  input[type="tel"] {
    &.error {
      border-color: var(--error);
      color: var(--error);
    }
  }
}

/* basket */
.basket {
  max-width: 600px;
  margin: 0 auto;
}

.free-shipping {
  font-size: 14px;
  padding-top: 10px;
}

.basket-text {
  font-size: 14px;
  padding-top: 10px;
}

.basket-bottom-row {
  padding-top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

/* billing */
.billing-holder {
  display: flex;
  border: 4px solid var(--dusty);

  &.column {
    flex-direction: column;
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.billing-form {
  width: 50%;
  padding: 31px 30px 30px;

  &.wide {
    width: 100%;
    position: relative;

    @media (max-width: 767px) {
      padding: 30px 0;
    }
  }

  .input-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;

    &.contact-data {
      padding-top: 5px;
    }
  }

  .input-holder {
    width: 50%;
    padding: 0 0 20px 20px;
    position: relative;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    &.full-width {
      width: 100%;
    }

    &.half-width {
      width: 50%;
    }

    .form-input {
      width: 100%;
    }

    &.align-right {
      justify-content: flex-end;
    }
  }

  .additional-area {
    display: flex;
    width: 100%;
    visibility: hidden;
    height: 0;
  }

  .additional-area {
    display: flex;
    width: 100%;
    visibility: hidden;
    height: 0;

    &.visible {
      visibility: visible;
      height: auto;
    }
  }

  .error-form-popup p {
    margin: 0 0 30px;
  }
}


.billing-form-holder {
  padding-bottom: 30px;
  border-bottom: 2px solid var(--smoke);
  margin-bottom: 30px;
}

.billing-form-footer {
  display: flex;
  justify-content: center;
}

.mobile-sub-title {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
}

.billing-form-opener {
  background: none;
  border: 0;
  color: var(--pink);
  text-transform: uppercase;
  position: relative;
  padding-right: 22px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  outline: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 8px;
    height: 8px;
    border-color: var(--pink);
    border-style: solid;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
    margin-top: -6px;
  }

  &.active {
    &:before {
      transform: rotate(135deg);
      margin-top: -3px;
    }
  }
}
/* billing-summary-row */
.billing-summary-row .frame {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
}

.billing-summary-row .frame-col {
  flex: 1;
  padding: 0 0 0 15px;
}

.billing-summary-row .frame-col .btn {
  width: 100%;
}

.billing-summary-row .promo-code {
  border: 1px solid var(--dusty);
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex: 0 0 60%;
  margin: 0 0 0 15px;
}

.billing-summary-row .promo-code input[type='text'] {
  border-width: 0 1px 0 0;
  border-radius: 0;
  min-width: 0;
  height: 36px;
  flex-grow: 1;
}

.billing-summary-row .promo-code .apply-btn {
  text-transform: uppercase;
  background: none;
  border: none;
  color: var(--gray);
  text-align: center;
  height: 36px;
  line-height: 34px;
  transition: all .2s ease-in-out;
  border-radius: 0 4px 4px 0;
}

.billing-summary-row .promo-code .apply-btn:hover {
  background: var(--dusty);
  color: var(--white);
}

/* basket-summary */
.billing .basket-summary {
  border-left: 2px solid var(--smoke);
  padding: 30px;
  width: 50%;

  &.wide {
    width: 100%;
    padding-top: 0;

    .summary-table {
      margin-bottom: 0;

      tbody{
        tr {
          @media (min-width: 768px) {
            border-top: 0;
          }
        }
      }

      .price-row .price {
        margin-right: 0;
      }

      .price-row .price,
      .desktop-price {
        font-size: 14px;
        line-height: 18px;
      }

      .total-title {
        font-weight: bold;
      }
    }
  }
}

.basket-summary__heading {
  display: flex;
  justify-content: space-between;
}

.basket-summary__heading .link-info {
  flex-shrink: 0;
  margin: 10px 0 0 10px;
}

.billing .summary-table {
  margin-bottom: 25px;
  table-layout: fixed;
  border-bottom: 5px solid var(--smoke);
  width: 100%;

  th:first-child {
    width: 11.2%;
  }
  th:nth-child(2) {
    width: 33%;
  }
  th:nth-child(3) {
    width: 16%;
  }
  th:nth-child(4) {
    width: 13.8%;
  }
  th:nth-child(5) {
    width: 11%;
  }
  th:last-child {
    width: 15%;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }
}


.discount-description {
  display: none;
}

.mui-basket .remove:before {
  content: "\e5cd";
  font-family: "icomoon" !important;
  line-height: 1;
  color: var(--dark-gray);
  font-size: 20px;
}

.basket-old-price {
  color: var(--shade);
  text-decoration: line-through;
}

.checkout #header .header-holder {
  padding: 25px 0;
  flex-direction: row-reverse;
}

.billing-form__title {
  display: block;
  font-weight: normal;
  min-height: 36px;
  position: relative;
  padding: 0 0 0 41px;
  font-size: 20px;
}

.billing-form__heading {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}

.billing-form__item {
  width: 50%;
  padding: 0 0 27px 20px;
  border: 0;
  background: none;
  text-align: left;
  outline: 0;
}

.billing-form__item.hide {
  height: 0;
  width: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: -9999px;
}

.billing-form__number {
  position: absolute;
  left: 1px;
  top: 0;
  z-index: 2;
  min-width: 30px;
  min-height: 30px;
  color: var(--pink);
  background: var(--white);
  border: 1px solid var(--pink);
  border-radius: 50%;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  padding: 3px;
  text-align: center;
}

.billing-form__item.disabled .billing-form__number {
  color: var(--gray);
  background: var(--smoke);
  border-color: var(--smoke);
}

.billing-form__item.active .billing-form__number {
  color: var(--white);
  background: var(--pink);
  border-color: var(--pink);
}

.billing-form__prev {
  float: left;
  margin: 10px 10px 0 0;
}

.link-info {
  color: var(--pink);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}

.billing-form__edit-link {
  position: absolute;
  right: 0;
  top: -8px;
  z-index: 2;
  width: 30px;
  height: 30px;
  background: url(../images/pencil.svg) no-repeat center;
  background-size: 20px 20px;
  transition: all 0.2s linear;

  &:hover {
    background: url(../images/pencil-pink.svg) no-repeat center;
    background-size: 20px 20px;
  }
}

.billing-form .btn {
  border-radius: 4px;
  float: right;
  min-width: 110px;
  padding: 9px 10px;
  line-height: 18px;
}

.billing-form.order {
  .phone-number {
    text-decoration: none;
    white-space: nowrap;
  }

  .description {
    border: 1px solid var(--pig-pink);
    padding: 15px;
    background: var(--blush);
  }
}

.order__email {
  text-decoration: underline;
}

.billing-summary-row .promo-code-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// .billing-form .delivery__holder .input-holder {
//   padding: 0 0 30px 20px;
// }

.billing-form .input-item {
  width: 100%;
}

.billing-form .input-item.relative {
  padding-bottom: 20px;
}

.billing-form .input-row.tabs__content {
  display: none;
}

#checkout-form label.radio {
  position: relative;
  padding-left: 26px;
}

#checkout-form .checkbox.do-not-call {
  margin-left: -10px;
}

#checkout-form .radio .jcf-radio {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -9px;
}

@media (max-width: 1100px) {
  .billing-form {
    width: 40%;
  }

  .billing .basket-summary {
    width: 60%;
  }

  .product .related-products {
    padding: 0;
  }

  .billing-form .input-holder.full-width,
  .billing-form .input-holder {
    width: 100%;
  }

  .billing-form .additional-area {
    flex-wrap: wrap;

    .input-holder {
      width: 100%;
    }
  }
}

@media (max-width: 1023px) {
  .billing-holder {
    flex-direction: column;
    align-content: center;
    border: none;
  }

  .basket-summary {
    margin-bottom: 35px
  }

  .billing .basket-summary {
    width: 100%;
    padding: 0;
    border-left: 0;
    display: flex;
    flex-direction: column;
  }
  
  .billing .summary-table {
    margin-bottom: 15px;
  }

  .billing-summary-row {
    order: -1;
    margin-bottom: 25px;
  }

  .billing-form {
    width: 100%;
    padding: 0 0 35px;
  }

  .related-products .product-list {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .billing .basket-summary {
    padding: 0;
    border-left: none;
  }

  .btn-sticky-wrap {
    text-align: center;
    padding: 20px 0 0;
  }

  .billing .basket-summary {
    padding: 0;
    border-left: none;
  }

  .btn-sticky-wrap {
    text-align: center;
    padding: 20px 0 0;
  }

  .basket-bottom-row .link {
    order: 1;
    margin-top: 15px;
  }

  .basket-bottom-row {
    justify-content: center;
    flex-direction: column;
  }

  .basket-bottom-row .btn {
    margin-top: 20px;
  }

  .billing-holder {
    border: none;
  }

  .billing-form .btn {
    width: 100%;
    float: none;
  }

  .billing-form__prev {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin: 20px 0 0;
  }

  .input-holder--buttons {
    text-align: center;
  }
  .billing-form__title {
    min-height: 30px;
    padding: 0 0 0 42px;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
  }

  .billing-form__number {
    min-width: 30px;
    min-height: 30px;
    font-size: 14px;
    line-height: 20px;
    padding-top: 5px;
  }

  .billing-summary-row .promo-code-holder {
    display: block;
  }

  .billing-summary-row .promo-code {
    margin: 15px 0 0;
  }
  #checkout-form label.radio {
    flex-direction: column;
    margin-right: 10px;
    align-items: flex-start;
    min-width: 50%;
    max-width: 60%;
  }

  #checkout-form label.radio .radio-note {
    margin-left: 0;
  }

  #checkout-form .input-holder > .radio-note {
    text-align: right;
  }
}

@media (max-width: 567px) {

  .billing-summary-row .frame {
    flex-direction: column;
  }

  .billing-summary-row .frame-col + .frame-col {
    margin-top: 30px;
  }

  .basket-bottom-row {
    padding: 0;
  }

  .btn-sticky-wrap {
    position: sticky;
    bottom: 0;
    padding: 10px;
    margin: 0 -10px;
  }

  .btn-sticky-wrap .btn {
    width: 100%;
  }
}