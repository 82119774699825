.media-swiper {
    flex: 0 0 200px;
    height: 618px;
    
    @media (max-width: 1024px) {
      display: flex;
      width: 100%;
      height: auto;
      margin: 0 0 50px;
    }

    &.swiper {
      margin: 0 20px 0 0;

      @media (max-width: 1024px) {
        margin: 0 0 50px;
      }
    }

    picture {
      display: block;
      width: 100%;
      height: 100%;
    } 
    img {
      display: block;
      width: 100%;
      height: 100%;
      max-width: none;
      object-fit: cover;
    }

    .swiper-slide {
      height: 200px;
      width: 200px;
      cursor: pointer;
      box-sizing: border-box;

      &--video {
        position: relative;
        overflow: hidden;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      background: rgba(255, 255, 255, 0.95);
      cursor: pointer;
      display: block;
      box-shadow: 0 2px 7px #f1f1f1;
      z-index: 2;
      border: 0;
      padding: 0;
      overflow: hidden;
      text-indent: -9999px;
      transition: opacity 0.1s linear;
      position: absolute;
      left: 50%;
      width: 140px;
      height: 40px;
      margin: 0 0 0 -70px;
      opacity: 0.8;

      @media (max-width: 1024px) {
        width: 40px;
        height: 140px;
      }

      &:hover {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: url(../images/icon-arrow-right.svg) no-repeat 50% 50%;
      }

      &.swiper-button-disabled {
        opacity: 0;
        cursor: default;
      }
    }

    .swiper-button-prev{
      top: 100%;
      margin: -40px 0 0 -70px;

      @media (max-width: 1024px) {
        left: 0;
        top: 50%;
        margin: -75px 0 0;
      }

      &:before {
        top: 2px;
        transform: rotate(90deg);

        @media (max-width: 1024px) {
          transform: rotate(180deg);
          left: -4px;
        }
      }
    }

    .swiper-button-next{
      top:0;

      @media (max-width: 1024px) {
        left: auto;
        right: 0;
        top: 50%;
        margin: -75px 0 0;
      }

      &::before {
        top: -4px;
        transform: rotate(-90deg);

        @media (max-width: 1024px) {
          top: 0;
          transform: none;
          left: 4px;
        }
      }
    }
  }