.category-nav {
    flex: 0 0 255px;
    margin: 0 0 20px 20px;
    width: 255px;

    @media (max-width: 1199px) {
        display: none;
    }

    &__item {
        border: 1px solid var(--dusty);
        border-width: 1px 0;
        padding: 20px 0;

        &:first-child {
            padding-top: 0;
            border-top: 0;
        }
    }

    &__title {
        display: block;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 0 15px;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            padding: 10px 0 0;

            &:first-child {
                padding-top: 0;
            }

            &:before {
                display: none;
            }
        }

        a {
            text-decoration: none;

            &:hover {
                color: var(--pink);
            }
        }
    }
}
