.preamble {
  position: relative;
  padding: 0 0 40px;

  &.active {
    .preamble__text:before {
      display: none;
    }

    .preamble__opener .expand-label {
      display: none;
    }
    
    .preamble__opener .collapse-label {
      display: inline;
    }
  }

  &.disabled{
    padding: 0 0 20px;

    .preamble__text{
      max-height: none;
    }

    .preamble__text:before{
      display: none;
    }

    .preamble__opener{
      display: none;
    }
  }
}

.preamble__text {
  max-height: 180px;
  overflow: hidden;
  transition: max-height .3s linear;
  position: relative;
  margin-bottom: 15px;

  &:before {
    content: '';
    position: absolute;
    z-index: 9;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 76px;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 71%, var(--white) 100%);
  }
}

.preamble__text a,
.preamble__opener {
  transition: color .2s ease-in-out;
  color: var(--pink);

  &:hover {
    text-decoration: none;
  }
}

.preamble__opener .expand-label {
  display: inline;
}

.preamble__opener .collapse-label {
  display: none;
}