.blog-section {
  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .product-visual {
    margin-bottom: 20px;

    .product-gallery.swiper {
      padding: 0;
    }

    @media (min-width: 768px) {
      float: left;
      margin-right: 20px;
    }
  }

  &__title {
    margin-bottom: 30px;
    display: block;

    h1,
    strong {
      font-size: 20px;
      line-height: 1.25;
      text-transform: uppercase;
      font-weight: 500;
    }

    &--desktop {
      display: none;

      @media (min-width: 1200px) {
        display: block;
      }
    }

    &--mobile {
      text-align: center;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }

  &__author {
    margin-bottom: 20px;
    font-size: 12px;
    color: gray;

    @media (min-width: 1024px) {
      font-size: 14px;
    }

    * {
      color: gray;
    }

    span {
      font-style: italic;
    }
  }

  &__description {
    text-align: justify;
  }

  &__content {
    margin-bottom: 30px;

    a {
      transition: color 0.2s ease-in-out;
      color: var(--pink);

      &:hover {
        text-decoration: none;
      }
    }
  }
}
