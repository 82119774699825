
/* bottom text block */
.bottom-text-block .two-columns {
  column-count: 2;
  column-gap: 40px;
}

.bottom-text-block a {
  color: var(--pink);
  text-decoration: none;
}

.bottom-text-block a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {

  .bottom-text-block .two-columns {
    column-count: 1;
  }
}