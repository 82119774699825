.analyzer {
  margin-top: -30px;

  @media (max-width: 1279px) {
    margin: -30px -20px 0;
  }

  @media (max-width: 767px) {
    margin: -10px -10px 0;
  }

  &-container {
    margin: 0 auto;
    max-width: 728px;
  }

  .main-area {
    background-color: var(--light-cyan);
    padding: 30px 10px;
    margin: 0 0 40px;

    @media (max-width: 767px) {
      padding: 20px 10px;
      margin: 0 0 20px;
    }
  }

  .main-heading {
    display: flex;
    align-items: center;
    margin: 0 0 30px;

    @media (max-width: 767px) {
      align-items: flex-start;
      margin: 0 0 22px;
    }

    .icon-holder {
      margin: 0 25px 0 0;

      @media (max-width: 767px) {
        margin: 0 15px 0 0;
      }
    }

    h1 {
      margin: 0 0 5px;
    }

    p {
      margin: 0;
    }

    [class^="icon-"] {
      font-size: 58px;
      color: var(--turquoise);

      @media (max-width: 767px) {
        font-size: 50px;
      }
    }
  }

  &-description {
    @media (max-width: 767px) {
      padding: 0 10px;
    }

    h6 {
      margin: 0 0 5px;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    p {
      margin: 0;
    }
  }

  .text-box {
    margin: 0 0 20px;
  }
}

.analyzer-search {
  position: relative;

  .inner-area {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;

    @media (max-width: 767px) {
      display: block;
      margin: 0 0 19px;
    }
  }

  .input-holder {
    flex-grow: 1;
    margin: 0 10px 0 0;
    position: relative;

    @media (max-width: 767px) {
      margin: 0 0 10px;
    }
  }

  input[type=search] {
    font-size: 14px;
    width: 100%;
    padding: 0 10px 0 40px;

    @media (max-width: 767px) {
      font-size: 16px;
    }

    &:focus {
      font-size: 14px;
      border: 1px solid var(--pink);

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: url(../images/inputVector.png);
      cursor: pointer;
    }
  }

  .btn {
    min-width: 150px;

    @media (max-width: 767px) {
      width: 100%;
      height: 40px;
    }
  }

  [class^="icon-"] {
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: none;
    padding: 0;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .note {
    font-size: 13px;
    line-height: 1.2;
  }

  .note-title {
    @media (max-width: 767px) {
      display: block;
      margin: 0 0 7px;
    }
  }
}

.analyzer-results {
  padding: 30px 0 0;

  @media (max-width: 767px) {
    padding: 25px 0 0;
  }

  h2 {
    font-size: 20px;
    line-height: 1.2;
    margin: 0 0 15px;

    @media (max-width: 767px) {
      font-size: 18px;
      margin: 0 0 13px;
    }
  }

  .results-area {
    background-color: white;
    border: 1px solid var(--dusty);
    border-radius: 4px;
  }

  .no-results {
    background-color: white;
    border: 1px solid var(--dusty);
    border-radius: 4px;
    text-align: center;
    padding: 15px;

    .description {
      display: block;
      font-size: 16px;
      line-height: 1.2;
      margin: 0 0 15px;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    .sub-description {
      display: block;
      font-size: 13px;
      line-height: 1.2;
      margin: 0 0 15px;
    }

    .link-list {
      display: flex;
      justify-content: center;
      font-weight: 500;

      li {
        padding: 0;

        &:before {
          display: none;
        }

        &:not(:last-child) {
          margin: 0 10px 0 0;

          &:after {
            content: ','
          }
        }
      }

      a {
        text-decoration: none;
        color: var(--pink);
      }
    }
  }
}

.analyzer-diagram {
  padding: 20px;
  border-bottom: 1px solid var(--dusty);

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.diagram-area {
  position: relative;
  height: 20px;
  border-radius: 50px;
  background: white;
  margin: 0 0 15px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  gap: 2px;

  .box {
    flex-grow: 1;
  }

  .red {
    background: var(--cinnabar);
  }

  .orange {
    background: var(--poppy);
  }

  .green {
    background: var(--green-haze);
  }
}

.diagram-description {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;

  @media (max-width: 767px) {
    display: block;
  }

  li {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 20px 0 0;

    &:not(:last-child){
      margin: 0 20px 0 0;

      @media (max-width: 767px) {
        margin: 0 0 6px;
      }
    }

    &:before {
      display: none;
    }
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin: 0 8px 0 0;

    &.red {
      background: var(--cinnabar);
    }

    &.orange {
      background: var(--poppy);
    }

    &.green {
      background: var(--green-haze);
    }
  }
}

.ingredients-heading {
  padding: 15px 0;
  background: var(--smoke);
  font-size: 13px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.ingredients-columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  @media (max-width: 767px) {
    padding: 0 10px;
  }

  .column {
    padding: 0 5px;
    word-break: break-all;
  }

  .name {
    flex-grow: 1;
  }

  .ewg {
    width: 60px;
    flex-shrink: 0;

    @media (max-width: 767px) {
      width: 40px;
    }
  }

  .bio {
    width: 120px;
    flex-shrink: 0;

    @media (max-width: 767px) {
      width: 100px;
    }
  }

  .add {
    width: 130px;
    flex-shrink: 0;

    @media (max-width: 767px) {
      width: 25px;
    }
  }
}

.ingredients-list {
  li {
    padding: 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--dusty);
    }

    &:before {
      display: none;
    }
  }

  .ingredients-columns {
    .column {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .ewg {
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }

  .name-box {
    position: relative;
    padding: 0 0 0 15px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 5px;
      background: white;
      border-radius: 10px;
    }
  }

  .red {
    .name-box:before {
      background: var(--cinnabar);
    }
  }

  .orange {
    .name-box:before {
      background: var(--poppy);
    }
  }

  .green {
    .name-box:before {
      background: var(--green-haze);
    }
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0;
  }

  .note {
    font-size: 12px;
    line-height: 20px;
    display: block;
    color: var(--dark-gray);

    @media (max-width: 767px) {
      font-size: 13px;
    }
  }

  .ingredients-opener {
    text-decoration: none;
    color: var(--pink);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;

    &.active {
      [class^="icon-"] {
        transform: rotate(180deg);
      }
    }

    [class^="icon-"] {
      font-size: 16px;
      margin: 0 0 0 5px;
    }

    .text {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .content-area {
    padding: 0 20px 15px 20px;
    display: none;

    @media (max-width: 767px) {
      padding: 0 15px 15px 15px;
    }

    &.active {
      display: block;
    }

    p {
      font-size: 12px;
      line-height: 20px;
      color: var(--raven-black);
      margin: 0;
    }
  }
}


