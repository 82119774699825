.promo-code-input {
  margin-left: 15px;
}

.input-validation-error {
  border: 1px solid var(--error) !important;
}

label.disabled {
  color: var(--shade);
  pointer-events: none;
}

.check-mark {
  transform: rotate(45deg);
  height: 30px;
  width: 15px;
  border-bottom: 6px solid var(--pink);
  border-right: 6px solid var(--pink);
  margin-left: 20px;
  margin-bottom: 7px;
  margin-right: 7px;
}

.incorrect-promo-code-label {
  color: var(--red);
  padding: 5px 0;
}

.correct-promo-code-label {
  color: var(--fern);
  padding: 5px 0;
}

.input-prommocode-holder {
  display: flex;
}

.promo-code__label {
  display: block;
}

.checkbox-disabled {
  color: gray;
}

.check-list .button a {
  text-decoration: none;

  &:hover {
    color: var(--pink);
  }
}

@media (max-width: 767px) {

  .promocode-checkbox {
    flex-wrap: wrap;
  }

  .promocode-checkbox.checkbox .jcf-checkbox {
    min-width: 16px;
  }

  .promocode-checkbox .checkbox-label {
    padding: 10px 0 10px;
  }

  .promocode-checkbox .promo-code-input {
    width: 100%;
    margin: 0px;
    flex: 1 1 100%;
    margin-left: 0;
  }

  .promocode-checkbox .input-prommocode-holder {
    width: 100%;
  }

  .incorrect-promo-code-label {
    margin-left: 0;
  }

  .correct-promo-code-label {
    margin-left: 0;
  }
}

.telegram-input {
    display: none;
}
