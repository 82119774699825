.top-banner {
  text-align: center;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 35px;
}

.main-banner-group {
  padding: 0 0 35px;
  position: relative;
  overflow: hidden;
}

.main-banner-group ul {
  display: flex;
}

.main-banner-group li {
  padding: 0;
  flex-shrink: 1;
}

.main-banner-group img {
  max-width: 100%;
  width: 100%;
}

.main-banner-group .swiper-pagination {
  bottom: 6px;
  display: none;
}

.main-banner-group .swiper-pagination-bullet-active {
  background: var(--pink);
}

.main-banner-group li:before {
  display: none;
}

.main-banner-group a {
  display: block;
}

@media (max-width: 1023px) {
  .main-banner-group li {
    flex-shrink: 0;
  }
}

@media (max-width: 1023px) {
  .main-banner-group .swiper-pagination {
    display: block;
  }
}

@media (max-width: 767px) {
  .main-banner-group {
    margin: 0 -10px;
    padding: 0 0 40px;
  }
}
