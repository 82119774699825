.home-section {
  .heading {
    .h2, h2, a {
      font-size: 28px;
      line-height: 28px;
    }
    a {
        text-decoration:none;

        &:hover{
            color: var(--pink);
        }
    }
  }

  .related-products {
    padding-top: 0;
  }
}

.home-analyzer-anchor {
  background: var(--light-cyan);
  border: 1px solid rgba(23, 153, 138, 0.1);
  border-radius: 4px;
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 35px;

  @media (max-width: 767px) {
    padding: 10px;
    margin: 0 0 17px;
    justify-content: space-between;
  }

  .icon-tree {
    font-size: 44px;
    color: var(--turquoise);
    margin: 0 27px 0 0;

    @media (max-width: 767px) {
      font-size: 30px;
      margin: 0 15px 0 0;
    }
  }

  .text-holder {
    padding: 0 40px 0 0;

    @media (max-width: 767px) {
      padding: 0 15px 0 0;
      margin: 0 auto 0 0;
    }
  }

  .text {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .btn {
    min-width: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    [class^="icon-"] {
      margin: 0 0 0 8px;
      font-size: 7px;
    }
  }
}
