
.side-filters-item {
  border-top: 1px solid var(--dusty);
  padding: 20px 0;
}

.side-filters-item:last-child {
  border-bottom: 1px solid var(--dusty);
}

.side-filters-title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.side-filters .jcf-scrollable {
  height: 210px;
}

.side-filters .btn {
  position: sticky;
  left: 0;
  bottom: 20px;
  width: 100%;
  margin-top: 30px;
}

.mobile-filters {
  position: relative;
  border-top: 1px solid var(--dusty);
  padding: 5px;
  margin: 0 -10px;
}

.select-holder .mobile-filters {
  border-top: none;
  //margin-right: 5px;
}

.select-holder .mobile-filters .btn {
  color: var(--white);
  background: var(--pink);
  height: 40px;
}

.mobile-filters.popup-active .filters-popup {
  visibility: visible;
  opacity: 1;
  z-index: 104;
}


.mobile-filters .filter-opener {
  display: block;
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  max-width: 300px;
  text-decoration: none;
  background: none;
  border: 0;
  color: var(--pink);
}

.filters-popup .mobile-search-form {
  padding: 20px 15px;
  border-bottom: 0;
}

.mobile-filters .filter-close {
  position: absolute;
  right: 5px;
  top: 10px;
  text-decoration: none;
  font-size: 25px;
  width: 35px;
  text-align: center;
}

.mobile-search-form-title {
  height: 50px;
  border-bottom: 1px solid var(--dusty);
  padding: 10px 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.mobile-filters .filters-popup {
  overflow: auto;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--white);
  z-index: -1;
  padding: 0 0 60px;
}

.mobile-filters .filters-popup .btn {
  width: 100%;
  height: 100%;
}

.filters-popup .btn-filter-apply {
  position: fixed;
  border-top: 2px solid var(--dusty);
  padding: 10px;
  width: 100%;
  bottom: 0;
  background-color: var(--white);
}

.mobile-filters .side-filters-item {
  padding: 15px 0;
  margin: 0 15px;
}

.brands__filter {
  border-top: 1px solid var(--dusty);
  border-bottom: 1px solid var(--dusty);
  margin: 0 0 43px;
  font-size: 16px;
  white-space: nowrap;
}

.brands-alphabet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 15px 15px 0;
  margin: 0 0 0 -15px;
}

.brands-alphabet__item {
  cursor: pointer;
  padding: 0 0 10px 1%;
}



.brands-alphabet__item:before {
  display: none;
}

.brands-alphabet__item a:hover,
.brands-alphabet__item a.active {
  color: var(--pink);
}

.brands__content {
  column-count: 5;
  column-gap: 20px;
}

.brands-item {
  display: none;
  margin-bottom: 35px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.brands-item.active {
  display: block;
}

.brands-letter {
  display: block;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 17.5px;
}

.brands-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap
}

.brands-list li {
  margin-bottom: 15px;
  page-break-inside: avoid;
  break-inside: avoid;
}

.brands-list a {
  text-decoration: none;
}

.brands-list a:hover {
  color: var(--pink);
}


/* filters-panel */
.filters-panel {
  margin: 0 0 40px;
}

.filter-opener .mobile-filters-mainbutton {
  display: flex;
  justify-content: center;
}

.filter-opener .mobile-filters-mainbutton-title {
  line-height: 22px;
  white-space: nowrap;
}

.filter-opener .mobile-filters-mainbutton svg {
  margin-left: 9px;
  width: 18px;
}



.filters-panel:after {
  content: '';
  clear: both;
  display: block;
}

.filters-panel .select-holder {
  float: right;
  margin-left: 50px;
}

.filters-panel .select-holder label {
  margin-right: 10px;
}

.selected-filters {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.selected-filters li {
  margin: 0 10px 10px 0;
  border: 1px solid var(--pink);
  height: 40px;
  padding: 5px 5px 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: var(--pink);
}

.selected-filters li:before {
  display: none;
}

.selected-filters .icon-close {
  font-size: 20px;
  margin-left: 5px;
}

.selected-filters .reset-filters {
  border: 1px solid var(--dusty) !important;
  color: var(--black);
}


@media (max-width: 1100px) {
  .filters-panel {
    margin: 0;
  }

  .filters-panel .select-holder {
    float: none;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    padding: 0 0 10px;
    border-bottom: 1px solid var(--dusty);
  }
}

@media (max-width: 768px) {
  .brands-alphabet {
    padding: 15px 0 0;
    justify-content: flex-start;
  }

  .brands-alphabet__item {
    padding: 0 0 10px 15px;
  }

  .filters-panel .select-holder {
    margin-left: -10px;
    margin-right: -10px;
    padding: 5px 10px;
    border-top: 1px solid var(--dusty);
  }

  .selected-filters {
    font-size: 12px;
  }

  .selected-filters li {
    height: 28px;
  }

  .selected-filters .icon-close {
    font-size: 16px;
  }

  .brands__content {
    column-count: 2;
    font-size: 12px;
  }

  .brands-letter {
    font-size: 22px;
  }
}

@media (max-width: 567px) {
  .select-holder .mobile-filters {
    width: 52%;
    max-width: 52%;
    margin-left: -5px;
  }

  .select-holder .jcf-select {
    width: 48%;
    max-width: 48%;
  }

  .select-holder .mobile-filters .filter-opener.mobile-visible.btn {
    min-width: 140px;
  }

  .mobile-filters-mainbutton-title {
    font-size: 12px;
  }
}

@media ( max-width: 366px) {
  .filter-opener .mobile-filters-mainbutton svg {
    display: none;
  }
}


@media (max-width: 167px) {
  .filters-panel {
    margin-bottom: 20px;
  }
}