#contacts-modal .social-networks li img {
  max-width: 35px;
}

.social-networks .instagram-icon {
  border-radius: 6px;
}

#contacts-modal .social-networks li a:before {
  content: '';
  position: absolute;
  left: 20%;
  top: 20%;
  width: 20px;
  height: 20px;
  margin: -1px 0 0 -1px;
  box-shadow: 0 0 16px 5px rgba(0,0,0,0.2);
  z-index: 1;
}

.contacts-modal__list {
  list-style: none;
  margin: 0 0 29px;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
}

.contacts-modal__list li {
  padding: 15px 0;
  border-bottom: 1px solid var(--dusty);
}

.contacts-modal__list li:before {
  display: none;
}

.contacts-modal__list a {
  text-decoration: none;
  color: var(--black);
  transition: color .2s ease-in-out;
}

.contacts-modal__list a:hover {
  color: var(--pink);
}

.contacts-modal__icon {
  margin: 0 10px 0 0;
}

.contacts-modal-opener {
  display: block;
  width: 30px;
  height: 30px;
  background: url(../images/ic-contact-us.svg) no-repeat center;
  margin: -2px 0 0 15px;

  span {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

.contacts-modal-opener:hover {
  background-image: url(../images/ic-contact-us-pink.svg);
}

.blocker {
  background-color: rgba(62, 62, 62, 0.4);
  padding: 0;
}

#contacts-modal {
  border-radius: 10px 10px 0 0;
  vertical-align: bottom;
  box-shadow: none;
  padding: 31px 15px 30px;
  text-align: center;
  width: 100%;
}

#contacts-modal .close-modal {
  background: none;
  top: 9px;
  right: 10px;
}

#contacts-modal .close-modal:after {
  content: '';
  position: absolute;
  top: 14px;
  left: 7px;
  z-index: 2;
  width: 20px;
  height: 2px;
  background: var(--dark-gray);
  transform: rotate(45deg);
  transition: background .2s ease-in-out;
}

#contacts-modal .close-modal:before {
  content: '';
  position: absolute;
  top: 14px;
  left: 7px;
  z-index: 2;
  width: 20px;
  height: 2px;
  background: var(--dark-gray);
  transform: rotate(-45deg);
  transition: background .2s ease-in-out;
}

#contacts-modal .close-modal:hover:after,
#contacts-modal .close-modal:hover:before {
  background: var(--pink);
}

.contacts-modal__title {
  display: block;
  color: var(--dark-gray);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 15px;
}


.info-popup,
.contact-popup,
.billing-form .error-form-popup {
  background: rgba(255, 255, 255, .8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 103;
}

.contact-popup,
.info-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.contact-popup-holder,
.info-popup-holder,
.billing-form .error-form-popup-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  margin: auto;
}

.contact-popup-frame,
.info-popup-frame,
.billing-form .error-form-popup-frame {
  background: var(--smoke);
  border: 1px solid var(--dusty);
  border-radius: 4px;
  color: var(--pink);
  font-weight: 700;
  text-align: center;
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: center;
}

.billing-form .error-form-popup-frame {
  padding: 30px;
}

.contact-popup-frame,
.info-popup-frame {
  color: var(--black);
  position: relative;
  max-width: 375px;
  padding: 30px 55px;

  @media (max-width: 479px) {
    padding: 30px 35px;
  }
}

.contact-popup-row {
  margin-bottom: 20px;
}

.contact-popup-row input {
  width: 100%;
  font-weight: normal;
}

.contact-popup-title,
.info-popup-title {
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--pink);
}

.contact-popup .btn,
.info-popup .btn {
  border-radius: 4px;
  min-width: 110px;
  padding: 9px 10px;
  line-height: 18px;
}

.contact-popup-message {
  display: none;
  font-weight: normal;
}

.contact-popup-error {
  color: var(--error);
  font-weight: normal;
  display: none;
}

.contact-popup-error.show {
  display: block;
}

.contact-popup-form.hidden {
  display: none;
}

.contact-popup-message.show {
  display: block;
}

.contact-popup-message-text {
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--pink);
}

.contact-popup-close,
.info-popup-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background: none;
  border: 0;
  padding: 0;
}

.contact-popup-close:before,
.contact-popup-close:after,
.info-popup-close:before,
.info-popup-close:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  background: var(--black);
  transform: translate(-50%, -50%);
}

.contact-popup-close:before,
.info-popup-close:before {
  width: 100%;
  height: 2px;
}

.contact-popup-close:after,
.info-popup-close:after {
  width: 2px;
  height: 100%;
}
