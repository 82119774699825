#footer {
  background: var(--black);
  color: var(--white);
}

#footer .container {
  padding-top: 50px;
  padding-bottom: 63px;
}

#footer a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--pink);
  }
}

#footer .cols {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin: 0 0 30px;
}

#footer .col {
  width: 25%;
  position: relative;
}

#footer .col li {
  padding: 0 0 15px;
}

#footer li:before {
  display: none;
}

#footer .social-networks {
  position: absolute;
  right: 0;
  bottom: -80px;
  display: flex;
  z-index: 2;
}

#footer .footer-trust .cols {
  padding-bottom: 26px;
  border-bottom: 1px solid var(--dark-gray);
  margin: 0 -15px;
}

.col:last-child {
  text-align: right;
}

.footer-trust .col:last-child {
  text-align: left;
}

.footer-trust-title {
  margin-bottom: 10px;
  font-size: 12px;
}

.footer-trust-img {
  margin-right: 20px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.footer-trust-img svg {
  display: inline-block;
  vertical-align: middle;
}

#footer .footer-row {
  font-size: 12px;
  padding-top: 25px;
}

#footer .footer-row ul {
  overflow: hidden;
}

#footer .footer-row ul li:first-child + li {
  float: right;
}

#footer .footer-row ul li {
  display: inline-block;
  vertical-align: top;
  padding: 0;
}

// #footer .footer-row ul li:before {
//   display: none;
// }

#footer .footer-row ul > li + li {
  margin-left: 20px;
}

#footer .footer-row ul ul li + li {
  padding-left: 8px;
  margin-left: 7px;
  position: relative;
}

#footer .footer-row ul ul li + li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 12px;
  margin-top: -6px;
  background-color: var(--white);
  display: block;
}

@media (max-width: 1100px) {
  #footer .footer-row ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  #footer .footer-row ul li {
    padding: 0 5px 10px;
  }

  #footer .footer-row ul li:first-child {
    padding: 0 0 10px;
    flex: 0 0 100%;
  }

  #footer .footer-row ul li:first-child + li {
    float: none;
    order: 4;
  }

  #footer .cols {
    flex-wrap: wrap;
    margin: 0 auto 10px;
    width: 100%;
  }

  #footer .col {
    -ms-flex-preferred-size: 100%;
    margin: 0 auto;
    flex-basis: 100%;
    text-align: center;
    padding: 0;
    order: 2;
  }

  #footer .col:last-child {
    order: 1;
    border-bottom: 1px solid var(--dark-gray);
    padding: 0 0 10px;
    margin: 0 0 25px;
  }

  #footer .social-networks {
    position: static;
    justify-content: center;
    margin: 30px 0;
  }

  #footer .tel-list li {
    display: inline-block;
    vertical-align: top;
    padding: 0 15px;
  }

  #footer .footer-row ul {
    text-align: center;
  }

  #footer .footer-row ul > li + li {
    margin-left: 0;
  }

  #footer .footer-row ul ul li + li {
    padding-left: 0;
    margin-left: 0;
  }

  #footer .footer-row ul ul li + li:before {
    display: none;
  }

  #footer .tel-list {
    max-width: 420px;
    margin: 0 auto;
  }

  #footer .tel-list li {
    margin: 15px 0;
  }

  #footer .social-networks {
    margin: 25px 0 15px;
  }

  #footer nav.col ul {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }

  .footer-trust {
    padding: 0 10px;
  }

  #footer .footer-trust .cols {
    margin: 0 auto;
    padding: 25px 0;
  }

  #footer .footer-trust .cols:first-child {
    border-top: 1px solid var(--dark-gray);
    padding: 25px 0;
    align-items: center;
  }

  #footer .footer-trust .cols:first-child .col {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    border: 0;
  }

  #footer .footer-trust .cols:first-child .col ul li {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }
}

@media (max-width: 767px) {
  #footer .container {
    padding-top: 25px;
    padding-bottom: 58px;
  }
}
