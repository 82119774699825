.promotion-products-title {
  margin-bottom: 40px;
}

.promotion-products {
  padding-top: 50px;

  .product-list {
    display: flex;
    margin-left: 0;

    @media (max-width: 767px) {
      margin-left: 0;
      margin: 0 -10px;
    }

    .product-list-item {
      padding: 20px 20px 70px;
      min-width: 140px;

      @media (max-width: 767px) {
        padding: 0 10px 25px;
        width: 180px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .promotion-products .product-list-item {
    width: 33.33%;
  }
}

@media (max-width: 1023px) {
  .promotion-products {
    padding-top: 25px;
  }

  .product .promotion-products {
    padding-left: 0;
  }

  .promotion-products-title {
    margin-bottom: 20px;
  }
}
