.comments {
  &__item {
    margin-bottom: 25px;

    &:last-child {
      margin-top: 0;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  &__info {
    font-weight: 700;
    margin-right: 5px;
  }

  &__date {
    color: #808080;
    margin-right: 5px;

  }
}

.comments-rating {
  display: flex;
  margin-top: -3px;

  &__item {
    width: 16px;
    height: 13px;
    background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23e6b900%22%3E%3Cpath%20d%3D%22M2.6%2014l1.7-5.4L0%205.4h5.3L7%200l1.7%205.4H14L9.7%208.6l1.7%205.3L7%2010.7%202.6%2014zm.3-7.7l2.5%201.9-.9%203L7%209.5l2.5%201.9-.9-3%202.5-1.9H7.9L7%203.4l-.9%203-3.2-.1z%22%2F%3E%3C%2Fsvg%3E") 0 0;
  }

  &__item--active {
    background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23ffd200%22%3E%3Cpath%20d%3D%22M7%200l1.7%205.3H14L9.7%208.6l1.7%205.3L7%2010.7%202.7%2014l1.7-5.4L0%205.3h5.3L7%200z%22%2F%3E%3C%2Fsvg%3E") 0 0;
  }
}

.review-pagination {
  display: flex;
  justify-content: center;
  margin: 0 0 25px;

  .pagination {
    float: none;
    padding: 0;
    margin: 0;
    display: flex;

    li:last-child {
      margin-right: 0;
    }
  }
}

.review {
  position: relative;

  &__success {
    background: #f9f9fa;
    padding: 30px 75px;
    text-align: center;
    min-height: 200px;
    transition: all 0.2s;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .review.active & {
      opacity: 1;
      visibility: visible;
      position: static;
      z-index: 2;
    }
  }

  &__success-title {
    color: #f0719d;
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 20px;
  }

  &__content {
    transition: all 0.2s;
    opacity: 1;
    visibility: visible;
    position: static;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;

    .review.active & {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: 1;
    }
  }

  &__header {
    font-size: 16px;
    display: block;
    margin-bottom: 15px;
  }

  &__row {
    margin-bottom: 25px;
  }

  &__row--two-col {
    display: flex;
    column-gap: 20px;

    @media (max-width: 768px) {
      display: block;
    }

    input {
      flex: 1;

      @media (max-width: 768px) {
        width: 100%;
        margin-top: 25px;
      }

      &:first-child {
        @media (max-width: 768px) {
          margin-top: 0;
        }
      }
    }
  }

  textarea {
    width: 100%;
  }
}

.rating {
  display: flex;
  align-items: center;

  &__title {
    font-weight: normal;
    margin: 0 6px 0 0;
  }

  &__scale {
    display: flex;
  }

  &__item {
    cursor: pointer;
    width: 32px;
    height: 28px;
    margin-left: 4px;
    background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23e6b900%22%3E%3Cpath%20d%3D%22M2.6%2014l1.7-5.4L0%205.4h5.3L7%200l1.7%205.4H14L9.7%208.6l1.7%205.3L7%2010.7%202.6%2014zm.3-7.7l2.5%201.9-.9%203L7%209.5l2.5%201.9-.9-3%202.5-1.9H7.9L7%203.4l-.9%203-3.2-.1z%22%2F%3E%3C%2Fsvg%3E") 0 0;
    
    &.hover,
    &:hover {
      background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23ffd200%22%3E%3Cpath%20d%3D%22M7%200l1.7%205.3H14L9.7%208.6l1.7%205.3L7%2010.7%202.7%2014l1.7-5.4L0%205.3h5.3L7%200z%22%2F%3E%3C%2Fsvg%3E") 0 0;
    }
  }
}

.product-rating {
  display: flex;
  justify-content: space-between;

  .product & {
    justify-content: flex-start;
  }
}

.product-rating__stars {
  position: relative;
  width: 79px;
  height: 14px;
  background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23e6b900%22%3E%3Cpath%20d%3D%22M2.6%2014l1.7-5.4L0%205.4h5.3L7%200l1.7%205.4H14L9.7%208.6l1.7%205.3L7%2010.7%202.6%2014zm.3-7.7l2.5%201.9-.9%203L7%209.5l2.5%201.9-.9-3%202.5-1.9H7.9L7%203.4l-.9%203-3.2-.1z%22%2F%3E%3C%2Fsvg%3E") 0 0 repeat-x;
  
  .product-list & {
    width: 62px;
    height: 11px;
  }

  .product-list & {
    margin-bottom: 5px;
  }
}

.product-rating__current-stars {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23ffd200%22%3E%3Cpath%20d%3D%22M7%200l1.7%205.3H14L9.7%208.6l1.7%205.3L7%2010.7%202.7%2014l1.7-5.4L0%205.3h5.3L7%200z%22%2F%3E%3C%2Fsvg%3E") 0 0 repeat-x
}

.gl-star-rating {
  position: relative;
}

.star-rating {
  position: absolute;
  left: 10px;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.gl-star-rating--stars {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
}

.gl-star-rating .gl-star-rating--stars>span {
  width: 32px;
    height: 28px;
    margin-left: 4px;
    background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23e6b900%22%3E%3Cpath%20d%3D%22M2.6%2014l1.7-5.4L0%205.4h5.3L7%200l1.7%205.4H14L9.7%208.6l1.7%205.3L7%2010.7%202.6%2014zm.3-7.7l2.5%201.9-.9%203L7%209.5l2.5%201.9-.9-3%202.5-1.9H7.9L7%203.4l-.9%203-3.2-.1z%22%2F%3E%3C%2Fsvg%3E") 0 0;
}

.gl-star-rating .gl-star-rating--stars>span.gl-active {
  background: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%20fill%3D%22%23ffd200%22%3E%3Cpath%20d%3D%22M7%200l1.7%205.3H14L9.7%208.6l1.7%205.3L7%2010.7%202.7%2014l1.7-5.4L0%205.3h5.3L7%200z%22%2F%3E%3C%2Fsvg%3E") 0 0;
}