.btn {
  background: var(--pink);
  color: var(--white);
  padding: .5em .7em;
  font-size: inherit;
  border: 2px solid var(--pink);
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  min-width: 180px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  /* min-height: 38px; */
  cursor: pointer;
}

.btn:hover {
  color: var(--pink);
  background: var(--white);
  border-color: var(--pink);
}

.secondary-btn {
  background: var(--white);
  color: var(--pink);
  padding: .5em .7em;
  font-size: inherit;
  line-height: 20px;
  border: 2px solid var(--pink);
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  min-width: 180px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  height: 38px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.secondary-btn:hover {
  color: var(--white);
  background: var(--pink);
  border-color: var(--pink);
}


.btn--narrow {
  min-width: 0;
  font-size: 12px;
}

.btn--info {
  background: var(--green);
  border-color: var(--green);

  span {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    color: var(--green);
    background: var(--white);
    border-color: var(--green);
  }
}

.btn-review {
    background: var(--pink);
    color: var(--white);
    padding: .5em .7em;
    font-size: inherit;
    border: 2px solid var(--pink);
    border-radius: 4px;
    font-weight: 500;
    text-transform: uppercase;
    min-width: 180px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    /* min-height: 38px; */
    cursor: pointer;
}

.btn-review:hover {
    color: var(--pink);
    background: var(--white);
    border-color: var(--pink);
}