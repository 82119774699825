@import "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/css/intlTelInput.css";

.iti {
  width: 100%;
  font-weight: normal;

  &--container {
    @media (max-width: 767px) {
      width: auto;
    }
  }

  &__country-list {
    list-style: none;

    li {
      &:before {
        display: none;
      }
    }
  }

  &__selected-flag {
    height: 38px;
    bottom: auto;
  }
}