/* Product Quantity */
.quantity {
  width: 115px;
  display: flex;
  justify-content: center;

  input {
    -webkit-appearance: none;
    border: none;
    text-align: center;
    width: 40px;
    min-width: 40px;
    height: 30px;
  }

  button[class*=btn] {
    background-color: var(--white);
  }

  button,
  .quantity input {
    &:focus {
      outline: 0;
    }
  }

  .minus-btn,
  .plus-btn {
    display: inline-block;
    width: 29px;
    height: 29px;
    border: 1px solid var(--silver);
    position: relative;
    overflow: hidden;
    text-indent: -3000px;
    color: transparent;
    vertical-align: top;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 19px;
      border-top: 1px solid var(--silver);
      top: 50%;
      left: 50%;
      margin-left: -9.5px;
      margin-top: -0.5px;
    }

    &:hover {
      &:not(.disabled) {
        background-color: var(--silver);

        &:before {
          border-top-color: var(--white);
        }
      }
    }

    &.disabled {
      cursor: default;
    }
  }

  .plus-btn{
    &:after {
      content: '';
      position: absolute;
      height: 19px;
      border-left: 1px solid var(--silver);
      top: 50%;
      left: 50%;
      margin-top: -9.5px;
      margin-left: -0.5px;
    }
    &:not(.disabled) {
      &:hover {
        &:after {
          border-left-color: var(--white);
        }
      }
    }
  }
}