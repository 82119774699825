.tabset {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tabset li {
  border: solid transparent;
  border-width: 1px 1px 0;
  border-radius: 4px 4px 0 0;
  position: relative;
  padding-left: 0;

  @media (max-width: 767px) {
    padding-bottom: 1px;
  }
}

.tabset li:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: var(--white);
  height: 1px;
  width: auto;
  border-radius: 0;
  display: none;

  @media (max-width: 767px) {
    background: none;
  }
}

.tabset li:after {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  height: 2px;
  background-color: var(--pink);
  display: none;
}

.tabset li:hover,
.tabset li.active {
  border-color: var(--smoke);

  @media (max-width: 767px) {
    border-color: transparent;
  }
}

.tabset li:hover:after,
.tabset li:hover:before,
.tabset li.active:after,
.tabset li.active:before {
  display: block;
}

.tabset h2 {
  margin: 0;
  padding: 0;
}

.tabset a {
  font: 14px/1.43 "Roboto", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: var(--blush);
}

.tab-content {
  border: 1px solid var(--smoke);
  padding: 20px;
  border-radius: 0 4px 4px 4px;
}

.tab-content h3 {
  font: 14px/1.43 "Roboto", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: bold;
  color: var(--text-color);
}

.tab-content dl {
  margin: 0;
}

.tab-content dl dt {
  float: left;
  clear: left;
  padding-right: 5px;
  font-weight: 700;
}

.tab-content dl dd {
  margin: 0 0 13px;
}

.tab-content dl dd:last-child {
  margin-bottom: 0;
}

/* tabset required styles for hidden tabs */

.js-tab-hidden {
  display: block !important;
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important;
}

.tabset__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.js-tabs__btn {
  position: absolute;
  opacity: 0;
}

.tabs__content {
  display: none;
}
