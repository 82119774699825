.category-swiper {
  overflow: hidden;
  margin-bottom: 30px;
  display: flex;

  @media (max-width: 768px) {
    margin: 0 -10px 15px;
  }
}

.category-swiper .swiper-slide {
  max-width: 163px;
  min-width: 163px;
  text-align: center;
  box-sizing: border-box;
  height: auto;

  @media (max-width: 768px) {
    max-width: 29vw;
    min-width: 29vw;
  }

  &:hover {
    img {
      transform: scale(1.2);

      @media (max-width: 768px) {
        transform: none;
      }
    }
  }

  a {
    display: block;
    text-decoration: none;
    padding: 0 15px;
    height: 100%;

    @media (max-width: 567px) {
      padding: 0 7px;
    }

    &:hover {
      color: var(--pink);
    }
  }

  .image-holder {
    margin-bottom: 10px;
    overflow: hidden;
    border-radius: 50%;

    picture {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: relative;

      &:before {
        content: '';
        background: linear-gradient(0deg, rgba(240, 113, 157, 0.06), rgba(240, 113, 157, 0.06));
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }

    img {
      display: block;
      width: 100%;
      transition: transform .7s ease-in-out;
    }
  }

  .title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;

    @media (max-width: 1100px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}
