
/* order status */
.order {
  padding-top: 36px;
  line-height: 22px;
}

.order__title {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
  margin: 0 0 29px;
}

.order__status {
  font-size: 14px;
  line-height: 22px;
  padding: 0 10px;
  color: var(--black);
  display: inline-block;
  margin-bottom: 25px;

  &.not-paid {
    background: rgba(219, 130, 0, 0.1);
    border-radius: 4px;

    &:before {
      content: '';
      margin-right: 6px;
      width: 6px;
      height: 6px;
      display: inline-block;
      vertical-align: middle;
      background: var(--caramel);
      border-radius: 50%;
      margin-top: -3px;
    }
  }

  &.paid {
    background: rgba(40, 160, 27, 0.1);
    border-radius: 4px;

    &:before {
      content: '';
      margin-right: 6px;
      width: 6px;
      height: 6px;
      display: inline-block;
      vertical-align: middle;
      background: rgba(40, 160, 27, 1);
      border-radius: 50%;
      margin-top: -3px;
    }
  }
}

.order__subtitle {
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 9px;
}

.order__number {
  color: var(--pink);
}


.order-info {
  color: var(--dark-gray);
  font-size: 14px;
  line-height: 18px;
  padding: 1px 0 0;
}

.order-info__title {
  display: block;
  font-weight: 700;
}

.order-info__item {
  padding: 0 0 18px;
}

@media (max-width: 767px) {
  #checkout-form .order-info__holder {
    border-bottom: 1px solid var(--dusty);
  }
}