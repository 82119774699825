
#footer .social-networks li,
.social-networks li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 13px;
}

.social-networks li:before {
  display: none;
}

.social-networks li:first-child {
  padding: 0;
}

.social-networks li a {
  display: block;
  position: relative;
}

.social-networks li img {
  max-width: 30px;
  position: relative;
  z-index: 2;
}