@media (prefers-color-scheme: light) {
    :root{
        --white: #fff;
        --black: #000;
        --text-color: #000;
        --bkg-color: #fff;
        --smoke: #f1f1f1;
        --dark-smoke: #e0e0e0;
        --silver: #ccc;
        --dusty: #dbdbdb;
        --gray: #aaa;
        --shade: #b1b1b1;
        --dark-gray: #808080;
        --raven-black: #3E3E3E;
        --dark: #1e1e1e;
        --poppy: #ff9e18;
        --caramel: #db8200;
        --blush: #fff6fa;
        --pig-pink: #fbdde8;
        --pink: #f0719d;
        --error: #f00;
        --cinnabar: #e43d30;
        --red: #d81414;
        --light-cyan: #e0f8f5;
        --turquoise: #87e3d8;
        --pool-green: #00ad99;
        --lemon: #25d366;
        --green: #24b300;
        --green-haze: #009d4f;
        --fern: #579445;
        --sky: #448aff;
        --dark-blue: #4267B2;
    }
}

@media (prefers-color-scheme: dark) {
    :root{
        --white: #fff;
        --black: #000;
        --text-color: #000;
        --bkg-color: #fff;
        --smoke: #f1f1f1;
        --dark-smoke: #e0e0e0;
        --silver: #ccc;
        --dusty: #dbdbdb;
        --gray: #aaa;
        --shade: #b1b1b1;
        --dark-gray: #808080;
        --raven-black: #3E3E3E;
        --dark: #1e1e1e;
        --poppy: #ff9e18;
        --caramel: #db8200;
        --blush: #fff6fa;
        --pig-pink: #fbdde8;
        --pink: #f0719d;
        --error: #f00;
        --cinnabar: #e43d30;
        --red: #d81414;
        --light-cyan: #e0f8f5;
        --turquoise: #87e3d8;
        --pool-green: #00ad99;
        --lemon: #25d366;
        --green: #24b300;
        --green-haze: #009d4f;
        --fern: #579445;
        --sky: #448aff;
        --dark-blue: #4267B2;
    }
}
