
.top-nav {
  background: var(--dark);
  color: var(--dark-smoke);
}

.top-nav-holder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.top-nav .container {
  padding-top: 0;
  padding-bottom: 0;

  @media (max-width:1100px) {
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.top-nav__list {
  font-size: 12px;
}

.top-nav__list > li {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
}

.top-nav__list > li:before {
  display: none;
}

.top-nav__list > li a {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 5px 8px;
}

.top-nav__list > li a:hover {
  background: var(--pink);
}

.header-section .currency-block {
  position: relative;
  
  &--desktop {
    @media (max-width:1100px) {
      display: none;
    }
  }

  &--tablet {
    display: none;

    @media (max-width:1100px) {
      display: block;
      z-index: 3;
    }
  }

  .jcf-select {
    @media (max-width: 1100px) {
      color: var(--black);
      border-color: var(--black);
      background: var(--white);
    }
  }

  .jcf-select .jcf-select-opener:after {
    @media (max-width: 1100px) {
      border-bottom-color: var(--black);
      border-right-color: var(--black);
    }
  }
}

.header-top-holder {
  position: relative;
}

.header-langs {
  display: flex;
  align-items: center;

  @media (max-width:1100px) {
    display: none;
  }
}

.top-nav__right {
  display: none;
  margin: 0 0 0 15px;

  @media (max-width: 1100px) {
    display: flex;
  }
}

.langs-block {
  text-transform: uppercase;
  display: block;
}

.top-nav .langs-block .jcf-select {
  @media (max-width: 1100px) {
    border-color: var(--dark-smoke);
  }
}

.header-langs {
  position: absolute;
  right: 7px;
  top: 0;
  height: 100%;
}

.main-nav__second-level {
  display: none;
}

.header-langs a {
  color: var(--shade);
  text-decoration: none;
  font-weight: 500;
}

.header-langs a.active {
  font-weight: bold;
  text-decoration: underline;
}

.main-nav-title {
  text-transform: uppercase;
  font-weight: 500;

}

.header-langs a.active,
.header-langs a:hover {
  color: var(--pink);
}

.header-langs .item {
  position: relative;
  padding: 0 13px 0 14px;
}

.header-langs .item:first-child:before {
  display: none;
}

.header-langs .item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 15px;
  background: var(--raven-black);
}

#header .container {
  padding-bottom: 0;
  padding-top: 0;
}

#header .header-holder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: start;
  padding: 35px 0;
  position: relative;
}

#header .logo {
  max-width: 42%;
  margin: 0 auto;
}

#header .logo img {
  vertical-align: top;
}

#header .left-col {
  color: var(--raven-black);
  font-weight: 400;
  font-size: 13px;
  min-width: 310px;
}

#header .left-col > * {
  margin-bottom: .8em;
}

#header .right-col {
  text-align: right;

  @media (max-width: 1279px) {
    min-width: 240px;
  }

  @media (max-width: 1100px) {
    min-width: 0;
    z-index: 2;
    display: flex;
  }
}

#header .cart {
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
  line-height: 25px;
  height: 25px;
  cursor: pointer;
  background: url('../images/icon_basket.svg') no-repeat 0 50% / 28px auto;
  padding: 0 0 0 25px;
  position: relative;

  @media (max-width: 1100px) {
    margin: 1px 0 0 20px;
  }

  a {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;

    @media (max-width: 1100px) {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

#header .cart.empty {
  background: url('../images/icon_basket_empty.svg') no-repeat 0 50% / 25px auto;
}

#header .cart.empty .num {
  display: none;
}

#header .cart .num {
  position: absolute;
  left: -5px;
  top: 7px;
  width: 20px;
  height: 20px;
  color: var(--black);
  font-size: 12px;
  line-height: 18px;
  border: 1px solid var(--white);
  background: var(--pink);
  border-radius: 50%;
  text-align: center;
}

#header .tel-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 .5em -10px;
  list-style: none;
  width: 270px;
  max-width: 100%;
}

#header .tel-list li {
  vertical-align: top;
  position: relative;
  padding: 0 10px;
}

#header .tel-list li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 14px;
  width: 1px;
  background: var(--black);
  margin-top: -7px;
}

#header .tel-list li:nth-child(2n -1):before {
  display: none;
}

#header .tel-list a {
  text-decoration: none;
}

.nav-opener {
  width: 40px;
  height: 40px;
  display: none;
}

.nav-active .nav-opener {
  position: fixed;
  z-index: 200;
}

.nav-active .nav-opener:before,
.nav-active .nav-opener:after {
  height: 2px;
}

.nav-active .nav-opener span {
  opacity: 0;
}

.nav-active .nav-opener:before,
.nav-active .nav-opener:after {
  transform: rotate(45deg);
  top: 50%;
  left: 15%;
  right: 15%;
  background: var(--white);
}

.nav-active .nav-opener:after {
  transform: rotate(-45deg);
}

.nav-opener span,
.nav-opener:before,
.nav-opener:after {
  background: var(--black);
  position: absolute;
  left: 7px;
  right: 7px;
  height: 4px;
}

.nav-opener span {
  top: 50%;
  margin-top: -2px;
}

.nav-opener:before {
  content: "";
  top: 10px;
}

.nav-opener:after {
  content: "";
  bottom: 10px;
}

.nav-active .overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.main-nav-holder {
  background-color: var(--white);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 102;
  border-bottom: 2px solid var(--black);
}

.main-nav-holder .container {
  padding-top: 0;
  padding-bottom: 0;
}

.main-nav-holder .only-mobile {
    display: none;
    border-top: 10px solid var(--dark);
}

.main-nav-holder .only-mobile .main-nav__list > li:first-child {
  border-top: 1px solid var(--raven-black);
}

.main-nav__list li {
  padding-left: 0;
}

.main-nav__list li:before {
  display: none;
}

.main-nav__list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
}

.main-nav__list > li {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  text-align: center;
  padding: 0;
  position: relative;
  max-width: 220px;
}

.main-nav__list svg {
  display: none;
}

.main-nav__list .promo{
  span {
    display: flex;
    align-items: center;
  }

  svg {
    display: block;
    margin-right: 4px;

    path {
      stroke: var(--red);
    }
  }
}

.main-nav__list > li:hover,
.main-nav__list > li.active {
  color: var(--pink);
}

.main-nav__list > li:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 2px;
  background: var(--pink);
  display: none;
}

.main-nav__list > li:hover:after,
.main-nav__list > li.active:after {
  display: block;
}

.main-nav__list > li:hover > a {
  border-color: var(--dusty);
  border-radius: 4px 4px 0 0;
}

.main-nav__list > li:before {
  content: "";
  position: absolute;
  top: auto;
  left: 1px;
  right: 1px;
  bottom: -3px;
  background: var(--white);
  border-radius: 0;
  width: auto;
  height: 3px;
  z-index: 11;
  display: none;
}

.main-nav__list > li:hover:before {
  display: block;
}

.main-nav__list > li:after {
  left: 10px;
  right: 10px;
  bottom: -2px;
  z-index: 12;
  display: none;
}

.main-nav__list > li:hover:after {
  display: block;
}

.main-nav__list > li:hover .drop {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.main-nav__list > li > a {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 10px;
  border: solid transparent;
  border-width: 1px 1px 0;
  font-weight: bold;
}

.main-nav__list .new a {
  color: var(--pool-green);
}

.main-nav__list .promo a {
  color: var(--red);
}

.main-nav__list .wholesale a {
  color: var(--green);
}

.main-nav .drop {
  position: absolute;
  z-index: 10;
  top: calc(100% + 2px);
  left: 0;
  width: 260px;
  background: var(--white);
  border: 1px solid var(--dusty);
  border-radius: 0 4px 4px 4px;
  padding: 15px;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: 0 0 0;
}

.main-nav__list ul > li:last-child .drop {
  left: auto;
  right: 0;
  border-radius: 4px 0 4px 4px;
}

.main-nav .drop ul {
  text-transform: none;
  font-size: 13px;
}

.main-nav .drop ul li {
  display: block;
  text-align: left;
  padding: 0;
}

.main-nav .drop ul li.active a {
  color: var(--pink);
}

.main-nav .drop ul li + li {
  margin-top: 10px;
}

.main-nav .drop ul a {
  text-decoration: none;
}

.main-nav .drop ul a:hover {
  color: var(--pink);
}

/* mobile search form */
.search-form.search-form--mobile{
  border: none;
  width: 100%;
  border-radius: 0;
  padding: 0;
  position: relative;
  transition: all 0.3s ease-out;
  transform: translateY(-100%);
  background: var(--white);

  .search-active &{
    transform: translateY(0);
  }

  .submit {
    top: 10px;
    left: 10px;
  }

  .search-list {
    position: static;
  }

  #autoSuggestSearchMobile {
    padding: 0 0 25px;
  }
}

.search-form__wrapper {
  padding: 10px 65px 10px 10px;
  border-bottom: 1px solid var(--dusty);
}
.search-opener {
  border: none;
  background: none;
  font-size: 22px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  display: none;
}

.search-form-holder {
  display: none;
  overflow: hidden;
  transition: all 0.3s ease-out;
  max-height: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 103;
}

.search-active .search-form-holder {
  max-height: 6000px;
}

@media (max-width: 1279px) {
  #header {
    border-bottom: 2px solid var(--dark);
  }

  #header .header-holder {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #header .left-col {
    min-width: 0;
  }

  .search-opener {
    display: flex;
    justify-content: center;
    align-items: center;
    right: 60px;
    top: 80px;
  }

  .search-form-holder {
    display: block;
  }

  .search-form {
    display: none;
  }

  .search-form.search-form--mobile {
    display: block;
  }

  .nav-opener {
    display: block;
    position: absolute;
    right: 20px;
    top: 80px;
  }

  .nav-active .nav-opener {
    left: 300px;
    top: 1px;
  }

  .nav-active .nav-opener:before,
  .nav-active .nav-opener:after {
    background: var(--black);
    transition: background-color 0.15s linear;
  }

  .main-nav {
    overflow: hidden;
  }

  .main-nav-holder {
    background: var(--black);
    padding: 15px 0;
    position: fixed;
    overflow: auto;
    top: 0;
    bottom: 0;
    left: 0;
    width: 290px;
    transform: translateX(-100%);
    transition: transform .2s;
  }

  .nav-active .main-nav-holder {
    transform: translateX(0);
    box-shadow: 0 0 0 999px rgba(255, 255, 255, .9);
    z-index: 104;
  }

  .resize-active .main-nav-holder {
    transition: none;
  }

  .main-nav-holder .container {
    padding-left: 0;
    padding-right: 0;
  }

  .main-nav__list {
    display: block;
    width: auto;
    color: var(--white);
    font-size: 13px;
    font-weight: 400;
  }

  .main-nav__list > li {
    display: block;
    text-align: left;
    max-width: 100%;
  }

  .main-nav__list > li:hover:before {
    display: none;
  }

  .main-nav__list > li.active:after,
  .main-nav__list > li:hover:after {
    display: none !important;
  }

  .main-nav {
    &__list {
      > li {
        border-bottom: 1px solid var(--raven-black);

        > a {
          border: none;
          padding: 15px 45px 15px 20px;
          transition: color 0.1s linear;
          text-transform: none;
        }

        &.active > a {
          color: var(--white);

          &:hover {
            color: var(--pink);
          }
        }
      }

      .new{
          span {
          display: inline-block;
          background: var(--turquoise);
          color: var(--black);
          line-height: 18px;
          font-size: 14px;
          padding: 3px 8px;
          font-weight: 400;
        }
      }

      .promo {
        span {
          display: inline-flex;
          align-items: center;
          background: var(--red);
          color: var(--white);
          line-height: 18px;
          font-size: 14px;
          padding: 3px 8px 3px 4px;
          font-weight: 400;
        }
        svg {
          path {
            stroke: var(--white);
          }
        }
      }

      .wholesale {
        span {
          display: inline-flex;
          align-items: center;
          background: var(--green);
          color: var(--white);
          line-height: 18px;
          font-size: 14px;
          padding: 3px 8px;
          font-weight: 400;
        }
      }
    }

    &__second-level-item {
      display: none;

      &.active {
        display: block;
      }
    }

    &__second-level-link {
      color: var(--white);
      display: flex;
      position: relative;
      cursor: pointer;

      a {
        flex: 1;
        color: var(--white);
        text-decoration: none;
        padding: 15px 45px 15px 20px;
        transition: color 0.15s linear;

        &:hover {
          color: var(--pink);
        }
      }
    }

    &__second-level-row {
      border-bottom: 1px solid var(--raven-black);
      transition: background 0.15s linear;

      &.has-list {
        &.active {
          .main-nav__second-level-link {
            &,
            a {
              color: var(--pink);
            }
          }
        }
      }
    }

    &__second-level-header {
      margin-top: -50px;
      padding: 15px 35px;
      border-bottom: 1px solid var(--raven-black);
    }

    &__second-level-heading {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      padding: 0 20px;

      a {
        color: var(--pink);
        text-decoration: none;
      }
    }
  }

  .drop {
    position: static;
    display: none;
    background: none;
    border: none;
    border-radius: 0;
    width: auto;
    padding-top: 0;

    ul {
      a {
        color: var(--white);
      }
    }
  }

  .main-nav__container {
    display: flex;
    max-width: 100%;
    width: 100%;
    transition: transform 0.15s linear;
  }

  .main-nav__list {
    min-width: 100%;
    max-width: 100%;
    transition: opacity 0.15s linear;
  }

  .main-nav__list li svg{
    display: block;
  }

  .main-nav__second-level {
    display: block;
    min-width: 100%;
    max-width: 100%;
    opacity: 0;
    transition: opacity 0.15s linear;
  }

  .main-nav.show-second-level .main-nav__container {
    transform: translateX(-100%);
  }

  .main-nav.show-second-level .main-nav__list {
    opacity: 0;
  }

  .main-nav.show-second-level .main-nav__second-level {
    opacity: 1;
  }

  .main-nav__list .has-drop a {
    position: relative;
    padding-right: 40px;
  }

  .main-nav__list .has-drop svg {
    position: absolute;
    right: 20px;
    top: 14px;
  }

  .main-nav__list .has-drop svg path{
    stroke: var(--white);
    transition: stroke 0.1ms linear;
  }

  .main-nav__list .has-drop a:hover svg path{
    stroke: var(--pink);
  }

  .main-nav__back-button {
    padding: 0;
    border: 0;
    background: none;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 2;
    margin: 15px 0 15px 20px;
    display: block;
  }

  .main-nav__back-button svg path{
    stroke: var(--white);
    transition: stroke 0.1s linear;
  }

  .main-nav__back-button:hover svg path{
    stroke: var(--pink);
  }

  .main-nav__second-level {
    color: var(--white);
  }

  .main-nav__second-level-row.active .main-nav__second-level-link {
    color: var(--pink);
  }

  .main-nav__additional-list {
    padding-left: 40px;
    display: none;
  }

  .main-nav__second-level-row.active {
    background: var(--dark);
  }

  .main-nav__additional-list-row {
    border-top: 1px solid var(--raven-black);
    position: relative;
    background: transparent;
  }

  .main-nav__second-level-row.has-list .main-nav__second-level-link .arrow {
    padding: 15px 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 50px;
  }

  .main-nav__second-level-row.has-list .main-nav__second-level-link svg path{
    stroke: var(--white);
    transition: stroke 0.1ms linear;
  }

  .main-nav__second-level-row.has-list .main-nav__second-level-link:hover {
    color: var(--pink);
  }

  .main-nav__second-level-row.has-list .main-nav__second-level-link svg path{
    color: var(--white);
    transition: stroke 0.15s linear;
  }

  .main-nav__second-level-row.has-list.active .main-nav__second-level-link svg {
    transform: rotate(180deg);
  }

  .main-nav__second-level-row.has-list .main-nav__second-level-link:hover svg path,
  .main-nav__second-level-row.has-list.active .main-nav__second-level-link svg path {
    stroke: var(--pink);
  }

  .main-nav__additional-list-row:first-child {
    border-top: none;
  }

  .main-nav__additional-list-row a {
    display: block;
    padding: 15px 15px 15px 0;
    text-decoration: none;
    color: var(--white);
    transition: color 0.1s linear;
  }
  .main-nav__additional-list-row a:hover {
    color: var(--pink);
  }
}

@media (max-width: 1100px) {
  .nav-opener {
    top: 50%;
    margin-top: -20px;
    right: auto;
    left: 0;
  }

  .nav-active {
    .nav-opener {
      top: 0;
      margin-top: 0;
    }
  }

  .search-opener {
    display: flex;
    top: 50%;
    left: 45px;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  #header .container {
    padding: 0 10px;
  }

  #header .header-holder {
    align-items: center;
    position: relative;
    padding: 5px 10px 5px 40px;
  }

  .checkout #header .header-holder {
    padding: 15px 0;
  }

  .checkout #header .logo {
    margin: 0 auto;
  }

  #header .cart .text {
    display: none;
  }

  .header-section .only-desktop {
      display: none;
  }

  .top-nav .top-nav__list {
    margin: 0 0 0 -14px;
  }
}

@media (max-width: 767px) {
  .nav-active .nav-opener {
    left: 329px;
  }

  .main-nav-holder {
    width: 320px;
  }

    .main-nav-holder .only-mobile {
        display: block;
    }

  #header .container {
    padding: 0;
  }

  #header .logo {
    transform: translateX(20px);
  }

  .top-banner {
    height: 18px;
    background-size: cover;
  }

  .top-nav .top-nav__list {
    flex: 1;
    margin: 0 0 0 -10px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .top-nav .top-nav__list > li {
    padding: 0 5px;
  }

  .top-nav .top-nav__list > li a {
    padding: 2px 4px;
  }
}

.top-nav select {
  height: 27px;
  padding: 4px 14px 4px 10px;
  font-size: 14px;
  line-height: 18px;
  width: 76px;
  background: none;
  border-color: transparent;
  color: var(--black);
}

.header-section .jcf-select {
  width: 66px;
  height: 26px;
  padding: 4px 14px 4px 8px;
  background: none;
  border-color: var(--dark-smoke);
  cursor: pointer;
  text-align: left;

  @media (max-width: 767px) {
    padding: 4px 12px 4px 4px;
  }

  .jcf-select-text{
    line-height: 18px;
  }
  
  &.jcf-focus .jcf-select-text{
    font-size: 14px;
  }

  .jcf-select-opener:after {
    top: 9px;
    border-bottom-color: var(--dark-smoke);
    border-right-color: var(--dark-smoke);
  }

  &.jcf-drop-active .jcf-select-opener:after {
    top: 12px;
  }

  .jcf-select-drop .jcf-option {
    color: var(--black);
  }
}
