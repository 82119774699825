
@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto-Italic.ttf') format('truetype');
    font-display: swap;
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-display: swap;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-display: swap;
    font-weight: 500;
    font-style: italic;
}
  
@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-display: swap;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-display: swap;
    font-weight: 700;
    font-style: italic;
}
