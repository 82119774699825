.product {
  padding-bottom: 35px;
}

.product .related-products {
  width: 100%;
}

#RelatedProductBlock {
  padding-top: 10px;
}

.product .related-products:before {
  content: '';
  clear: both;
  display: table;
}

.product:after {
  content: '';
  clear: both;
  display: block;
}

.product-heading {
  padding-bottom: 30px;
}

.product h1,
.product-name-title {
  font-size: 20px;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: 500;
}

.product,
.blog-section {
  .product-visual {
    float: left;
    width: 39%;
    position: relative;

    .slideset {
      .item-label-holder {
        left: 0;
        top: 0;
      }
    }
  }
}

.product .increased-img,
.blog-section .increased-img {
  position: absolute;
  top: -25px;
  left: 100%;
  z-index: -1;
  transform: translate(-25px, 0);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  transition: all 0.10s linear 0s;
  width: 50vw;
  max-width: 740px;
  
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 5px;
    border: 1px solid var(--pink);
    background: var(--white);
    overflow: hidden;
  }
}

.product .increased-img img,
.blog-section .increased-img img {
  max-height: 100%;
  object-fit: cover;
  flex:1
}

.product .increased-img.show,
.blog-section .increased-img.show {
  z-index: 103;
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
}

.fade-gallery-holder {
  position: relative;
  overflow: hidden;
}

.product .product-description {
  display: flex;
  padding-left: 30px;
}

.product .product-description.product-unavailable .price,
.product .product-description.product-unavailable .price-row .price.new-price {
  color: var(--dusty);
}

.product .product-description.product-unavailable .button-row,
.product .product-description.product-unavailable .old-price {
  display: none;
}

.product .product-description.product-unavailable .status-available {
  display: none;
}

.product-unavailable .price-row {
  display: none;
}

.product-unavailable .price-block {
  display: none;
}

.product .product-description.product-unavailable .status-unavailable {
  display: block;
}

.product .product-col {
  flex: 1;
  overflow: hidden;
}

.product .product-col-left {
  flex-grow: 2;
  padding: 0 3px 3px;
  margin: 0 -3px -3px;
}

.product .product-col-right {
  padding-left: 30px;
}

.product-info {
  margin: 0;
  padding: 0;
  list-style: none;
  
  @media (max-width: 1199px) {
    display: flex;
    justify-content: center;
  }
}

.product-info li {
  padding: 0 0 5px;

  @media (max-width: 1199px) {
      padding: 0 8px 0 10px;
      position: relative;
  }

  & + li:before {
    display: none;
    position: absolute;
    left: 0;
    top: 50%;
    height: 18px;
    width: 1px;
    background: #000;
    margin-top: -9px;

    @media (max-width: 1199px) {
      display: block;
    }
  }

  &:before {
    display: none;
  }
}

.product-info .brand {
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.product .v-code {
  display: block;
  color: #808080;
  margin-right: 5px;
}

.product .price-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  margin-bottom: 20px;
}

.product .product-col .price-row {
  justify-content: space-between;
}

.product .price {
  font-size: 30px;
  font-weight: 500;
  display: block;
}

.product .price.new-price {
  color: var(--red);
}

.product .old-price {
  font-size: 16px;
  font-weight: 500;
  color: var(--shade);
  text-decoration: line-through;
  display: block;
}

.price-inbtn .price-block .old-price {
  font-size: 14px;
}

.product .status {
  color: var(--green);
  font-weight: 500;
  padding-top: 6px;

}

.product .status-unavailable {
  color: var(--red);
  display: none;
}

.product .btn .added-text {
  display: none;
}

.product .added-item {
  .btn {
    background: var(--white);
    color: var(--pink);
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
  }
  
  .btn--info {
    background: var(--silver);
    color: var(--white);
    border-color: var(--silver);
    pointer-events: none;
  }
}

.product .added-item .btn .add-text {
  display: none;
}

.product .added-item .btn .added-text {
  display: inline-block;
  text-transform: none;
}

.product .added-item .btn .added-text:before {
  content: '';
  display: inline-block;
  background: url(../images/icon-check.png) no-repeat center/cover;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.product-share {
  position: relative;
}


.sharethis-inline-share-buttons {
  cursor: pointer;
}

.product-share-list {
  position: absolute;
  right: 100%;
  top: 50%;
  margin-top: -23px;
  background: var(--white);
  border: 1px solid var(--pink);
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 160px;
  margin-right: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-25px);
  transition: all 0.15s linear 0s;
}

.product-share.opened .st-btn {
  pointer-events: none;
}

.product-share.opened .product-share-list {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.product-share-list svg {
  max-width: 100%;
  width: 100%;
}


.product-share-btn {
  width: 26px;
  height: 26px;
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 50%;
}

.product-share-btn:focus,
.product-share-btn:active {
  border: 0;
  outline: none;
}

.product-share-btn.fb svg {
  height: 20px;
}

.product-share-btn.fb {
  background: var(--dark-blue);
}

.product-share-btn.wa svg {
  height: 18px;
}

.product-share-btn.wa {
  background: var(--lemon);
}

.product-share-btn.mes {
  background: var(--sky);
}

#st-1.sharethis-inline-share-buttons .st-btn img {
  display: none;
}

#st-1.sharethis-inline-share-buttons .st-btn {
  border: 0;
  width: 24px;
  height: 24px;
  background: url(../images/share-icon.svg) no-repeat 50% 50% / contain;
}

div[id^="st-el-"] {
  display: none;
}

.top-label {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--pink);
  border: 1px solid var(--pink);
  background-color: var(--white);
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  font-weight: 500;
  z-index: 3;
  display: none;
}

.item-label-holder {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.product-visual .item-label-holder {
  bottom: unset;
}

a.brand {
  text-decoration: none !important;
}

a.brand:hover {
  color: var(--pink);
}

.product-analyzer-anchor {
  background: var(--light-cyan);
  border: 1px solid rgba(23, 153, 138, 0.1);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-tree {
    font-size: 30px;
    color: var(--turquoise);
    margin: 0 15px 0 0;
  }

  .text-holder {
    padding: 0 15px 0 0;
    margin: 0 auto 0 0;
  }

  .text {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
  }

  .btn {
    min-width: 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    [class^="icon-"] {
      margin: 0 0 0 8px;
      font-size: 7px;
    }
  }
}

.top-sales-products {
  margin-top: 50px;

  @media (max-width: 767px) {
    margin-top: 1em;
  }

  .top-label {
    display: block;
  }
}

.product-heading--mobile {
  display: none;
}

.product-name--mobile {
  display: none;
}

.button-buy-row {
  display: flex;

  .price-inbtn {
    display: none;
  }

  .btn {
    min-width: 100%;
    
    @media (max-width: 1199px) {
      min-width: 157px;
      transform: translateX(-50%);

      .product .added-item & {
        transform: none;
      }
    }

    @media (max-width: 1024px) {
      min-width: 90px;
      width: 100%;
    }

  }
}
@media (max-width: 1223px) {
  .product .increased-img,
  .blog-section .increased-img {
    width: 50vw;
  }
}

@media (max-width: 1199px) {
  .product,
  .blog-section {
    .product-visual {
      float: none;
      width: auto;
      max-width: 530px;
      margin: 0 auto 70px;
    }

    .increased-img {
      left: 50%;
      top: 50%;

      picture {
        justify-content: center;
        align-items: center;
      }
    }

    .increased-img.show {
      transform: translate(-50%, -50%);
    }

    .product-description {
      flex-direction: column;
      padding-left: 0;
    }

    .product-col-right {
      padding: 0;
    }

    .price-row {
      flex-direction: row;
    }
  }

  .product-heading {
    text-align: center;
  }

  .product-heading--desktop {
    display: none;
  }

  .product-heading--mobile {
    display: block;
  }

  .product-info {
    li {
      display: inline-flex;
      padding: 0 8px 0 10px;
      position: relative;

      + li:before {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        height: 18px;
        width: 1px;
        background: var(--black);
        margin-top: -9px;
        border-radius: 0;
      }
    }
  }

  .product-name {
    position: inherit;
    margin-left: 0 !important;
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    h1 {
      width: 75%;
      margin: 0 auto 10px auto;
    }
  }

  .product-name--desktop {
    display: none;
  }

  .product-name--mobile {
    display: block;
  }

  .product .tab-area {
    margin-bottom: 22px;
  }
}

@media (max-width: 1100px) {
  .product .increased-img,
  .blog-section .increased-img {
    display: none;
  }
}

@media (min-width: 1024px) {
  .price-add-remove {
    width: 50%;
    display: flex;
    align-items: center;
  }
  .price-sign {
    width: 29px;
    height: 29px;
    border: 1px solid rgb(174, 174, 174);
    opacity: 0.53;
    background: linear-gradient(var(--gray), var(--gray)), linear-gradient(var(--gray), var(--gray));
    background-position: center;
    background-size: 70% 1px, 1px 70%;
    background-repeat: no-repeat;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }

  .price-sign.minus {
    background: linear-gradient(var(--gray), var(--gray));
    background-position: center;
    background-size: 70% 1px;
    background-repeat: no-repeat;
  }

  .price-number {
    width: 29px;
    height: 29px;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    padding: 5px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .price-number span {
    font-size: 16px;
    font-weight: normal;
    color: var(--black);
    vertical-align: middle;
  }

  .product .price-inbtn .price.new-price {
    text-align: right;
  }

  .product .price-inbtn .old-price {
    text-align: right;
  }

  .price-inbtn .price-block .price {
    text-align: right;
    margin: 0;
  }

  .price-mobile-btn {
    width: 27%;
  }
  
  .top-nav .container {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  #searchInputFilter {
    font-size: 16px;
  }

  .product-share {
    position: absolute;
    right: 8px;
    top: 9px;
  }

  .product-share-list {
    right: 0;
    margin-right: 0;
  }

  .product .added-item .well {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .well {
    width: 60%;
    padding-left: 6px;
  }

  .product .added-item .well {
    justify-content: start;
  }

  .price-add-remove {
    width: 44%;
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .product .product-visual,
  .blog-section .product-visual {
    margin-bottom: 25px;
  }

  .button-buy-row {
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .price-inbtn {
      display: block;
    }

    .btn {
      min-width: 90px;
      width: 100%;
      transform: none;
    }

    .price-block {
      display: block;
    }
  }

  .product .status {
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .product .price.new-price {
    white-space: nowrap;
  }
  .product {
    padding-bottom: 0;
  }

  .product-heading {
    padding-bottom: 20px;
  }

  .product .product-col-left {
    width: 100%;
    padding-top: 0;
  }

  .product .price {
    font-size: 25px;
  }

  .button-buy-row .well{
    width: 33%;
  }

  .product .product-visual,
  .blog-section .product-visual {
    margin-bottom: 0;
  }
}

.wholesale-row {
  flex-wrap: wrap;
  margin: 0 0 0 -6px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1199px) {
    justify-content: center;
    margin: 0 0 0 -10px;
  }

  @media (max-width: 1024px) {
    margin: -10px 0 0 -10px;
    padding: 10px;
    border-bottom: 1px solid var(--dusty);
  }

  .btn {
    margin: 10px 0 0 6px;
    padding: 6px 6px;
    flex: 1 0 calc(50% - 6px);

    @media (max-width: 1199px) {
      flex: 0 0 auto;
      min-width: 157px;
      margin: 10px 0 0 10px;
    }

    @media (max-width: 1024px) {
      min-width: 0;
    }
  }
}

.faq__title {
  display: block;
  margin: 0 0 1em;
}

.faq__item {
  border-top: 1px solid var(--smoke);
  padding: 1em 0 0;

  &:first-child {
    border: 0;
    padding-top: 0;
  }
}
