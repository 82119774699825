.trust-block {
  width: 100%;
  height: auto;
  background: var(--blush);
  padding: 20px;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--pig-pink);
  margin: 0 0 25px;
}

.trust-section {
  display: grid;
  border-bottom: 1px solid var(--pig-pink);
  margin-bottom: 15px
}

.delivery-section {
  border-bottom: 1px solid var(--pig-pink);
  margin-bottom: 15px
}

.trust-block-item {
  display: inline-flex;
  height: auto;
  margin-bottom: 15px;
}

.trust-section:nth-child(2) .trust-block-item img {
  text-align: center;
  width: 20px;
}

.trust-block-img {
  padding: 0;
  margin: 0;
  margin-right: 12px;
  display: flex;
  flex-shrink: 0;
}

.trust-block-descr {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  flex: 1;
}

.delivery-block {
  width: 100%;
  height: auto;
  background: var(--blush);
  padding: 20px;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--pig-pink);
}

.delivery-name {
  font: 600 14px/1.43 "Roboto", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
}

.delivery-block-item {
  display: inline-flex;
  margin-bottom: 15px;
}

.payment-section .trust-block-img {
  width: 30px;
}

.delivery-block-img {
  margin-right: 20px;
  width: 20px;
  align-items: center;
}

.delivery-block-pay-img {
  width: 36px;
  margin-right: 20px;
  align-items: center;
}

.delivery-block-descr {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.consultation .btn {
  min-width: 100%;
}

@media (max-width: 1199px) {
  .consultation {
    margin: 0 0 25px;

    .btn {
      min-width: 157px;
    }
  }
}

@media (max-width: 1100px) {
  .trust-block {
    padding-bottom: 5px;
  }

  .trust-section:nth-child(1) .trust-block-descr,
  .trust-section {
    display: block;
  }

  .trust-section:nth-child(1) .trust-block-img {
    margin-right: 12px;
  }

  .trust-block-item {
    margin-bottom: 15px;
  }

  .delivery-name {
    margin-bottom: 10px;
  }

  .trust-block-descr {
    display: none;
  }

  .trust-block-img {
    height: 20px;
    margin-right: 20px;
  }

  .col .social-networks {
    display: block;
  }

  .trust-right .social-networks {
    display: none;
  }

  .col:nth-child(3) {
    display: block;
  }
}

@media (max-width: 767px) {
  .delivery-section {
    display: grid;
  }

  .consultation .btn {
    min-width: 100%;
  }
}